import React, { useEffect, useState, useContext } from "react";
import { asyncLocalGet } from "../../../services/storage/local_storage";
import axios from "axios";
import { useHistory } from "react-router-dom";
import NISSAPLaceHolder from "../../../images/nissa_placeholder_image.png";
import { API } from "../../../services/config";
import { SocialProductTypes } from "./SocialProductTypes";
import { SocialMediaProductsContext } from "../../../contexts/SocialMediaProductsContext";

const SocialProduct = ({ parentCode, type }) => {
  /* Context variables */
  const {
    selectedProductsForAdd,
    setSelectedProductsForAdd,
    selectedProductsForDel,
    setSelectedProductsForDel,
  } = useContext(SocialMediaProductsContext);

  const history = useHistory();
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(false);
  const [border, setBorder] = useState("border-none");

  const onSelectHandler = (e) => {
    e.preventDefault();
    if (!selected) {
      if (type === SocialProductTypes.associated) {
        setSelected(!selected);
        setBorder("border-3 border-red-500");

        /* Remove from context */
        setSelectedProductsForDel([
          ...selectedProductsForDel,
          { parentCode: parentCode },
        ]);
      } else {
        setSelected(!selected);
        setBorder("border-3 border-green-500");

        /* Add to context */
        setSelectedProductsForAdd([
          ...selectedProductsForAdd,
          { parentCode: parentCode },
        ]);
      }
    } else {
      if (type === SocialProductTypes.associated) {
        setSelected(!selected);
        setBorder("border-none");
        /* Remove from context */
        setSelectedProductsForDel(
          selectedProductsForDel.filter((p) => p.parentCode !== parentCode)
        );
      } else {
        setSelected(!selected);
        setBorder("border-none");
        /* Remove from context */
        setSelectedProductsForAdd(
          selectedProductsForAdd.filter((p) => p.parentCode !== parentCode)
        );
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const getPresentationImage = async () => {
      const jwt = await asyncLocalGet("token");
      try {
        const result = await axios.post(
          API.explore.presentationImages,
          {
            parent_code: parentCode,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (result.status === 200) {
          setImage(result?.data?.images);
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setTimeout(() => {
              history.push("/");
            }, 500);
          }
        }

        setError(error.message);
        setImage(null);
      }
    };

    getPresentationImage().then((r) => r);
    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [history, parentCode]);

  return (
    <div className="col-span-1">
      <div
        className={`relative flex flex-col min-w-0 bg-gray-50 w-full shadow-lg rounded-lg mt-16 ${border}`}
      >
        <div className="px-0 rounded-lg">
          <div className="flex flex-wrap justify-center">
            <div className="w-full flex justify-center">
              <div className="relative b-3">
                {image ? (
                  <img
                    src={`data:image/jpeg;base64,${image.toString("base64")}`}
                    className="w-full h-auto object-contain rounded-lg"
                    onClick={(e) => onSelectHandler(e)}
                  />
                ) : (
                  <img
                    src={NISSAPLaceHolder}
                    className="object-cover w-full h-auto animate-pulse rounded-lg"
                    onClick={(e) => onSelectHandler(e)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-black">
              {parentCode}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialProduct;
