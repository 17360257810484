import React, { useState } from "react";
import axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";
import { API } from "../../services/config";
import { useHistory } from "react-router-dom";
import TargetInfo from "./TargetInfo";
import TargetEditForm from "./TargetEditForm";
import StoreError from "./error/StoreError";

const StoreCard = ({ store }) => {
  const [error, setError] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const history = useHistory();

  /* Custom rerender logic state */
  //TODO: add documentation for this
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updateCallback = (
    e,
    firstTargetValue,
    secondTargetValue,
    thirdTargetValue,
    fourthTargetValue
  ) => {
    e.preventDefault();

    if (
      isNaN(parseInt(firstTargetValue)) ||
      isNaN(parseInt(secondTargetValue)) ||
      isNaN(parseInt(thirdTargetValue)) ||
      isNaN(parseInt(fourthTargetValue))
    ) {
      setError("The specified values are not numbers!");
    } else {
      const updateTargetValues = async () => {
        const jwt = await asyncLocalGet("token");
        const data = {
          location: store,
          target: {
            first: firstTargetValue,
            second: secondTargetValue,
            third: thirdTargetValue,
            fourth: fourthTargetValue,
          },
          date: `${year}-${month}-01`,
        };

        try {
          const result = await axios.post(API.stores.updateTarget, data, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          if (result.status === 200) {
            setError("The store target data cannot be loaded!");
            setShouldUpdate(!shouldUpdate);
          }
        } catch (error) {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/");
            }
          }
          setError(error.message);
        }
      };

      updateTargetValues().then((r) => r);
    }
  };

  const handleYearOnChange = (e) => {
    setYear(e.target.value);
  };

  const handleMonthOnChange = (e) => {
    setMonth(e.target.value);
  };

  return (
    <div
      className="flex flex-col sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-lg
    border-none overflow-hidden shadow-lg rounded-lg h-auto cursor-pointer m-auto transition duration-500
    ease-in-out transform hover:translate-y-1 p-10 w-full"
    >
      {/* Year selector */}
      <div className="flex justify-between">
        <div className="p-3 border-none rounded-xl flex justify-center">
          <label className="p-0 font-semibold"> Year: </label>
          <select
            className="border-none appearance-none pt-0 pb-0 font-semibold text-sm bg-transparent"
            onChange={(e) => handleYearOnChange(e)}
            value={year}
          >
            {[...Array(30).keys()]
              .map((i) => i + 2018)
              .map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
          </select>
        </div>

        {/* Month selector */}
        <div className="p-3 border-none rounded-xl flex justify-center">
          <label className="p-0 font-semibold"> Month: </label>
          <select
            className="border-none appearance-none pt-0 pb-0 font-semibold text-sm bg-transparent"
            onChange={(e) => handleMonthOnChange(e)}
            value={month}
          >
            {[...Array(12).keys()]
              .map((i) => i + 1)
              .map((m) => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
          </select>
        </div>
      </div>

      <hr className="mt-2 mb-2 h-2" />

      <div className="grid grid-cols-2">
        {/* Target info*/}
        <TargetInfo
          storeName={store}
          year={year}
          month={month}
          shouldUpdate={shouldUpdate}
        />
        {/* Target edit form */}
        <TargetEditForm updateCallback={updateCallback} />
      </div>

      {error && <StoreError message={error} />}
    </div>
  );
};

export default StoreCard;
