import React, { useEffect, useState } from "react";
import { StoresMap } from "../../utils/StoresMap";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import StoreCard from "../../partials/stores/StoreCard";
import { useHistory } from "react-router-dom";

const Stores = () => {
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [stores, setStores] = useState(
    Object.entries(StoresMap)
      .filter((e) => e[1] !== "online")
      .map((e) => e[1])
  );

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setStores(
        Object.entries(StoresMap)
          .filter((e) => e[1] !== "online")
          .map((e) => e[1])
      );
    } else {
      setStores(
        stores.filter((store) =>
          store.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    /* Verify if navigation was made via dashboard click from sales by stores table */
    if (history.location.state) {
      if (history.location.state.location) {
        setSearchValue(history.location.state.location);
        setStores([history.location.state.location.toLowerCase()]);
      }
    }
  }, [history]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Social filters */}
            <div className="rounded-lg shadow-lg bg-gray-50">
              <form className="px-8 pt-0 pb-8 mb-4 rounded">
                <h3 className="pt-4 text-4xl text-left font-bold mb-10">
                  Stores
                </h3>
                <div className="border-none">
                  <div className="mb-6 flex justify-center w-full">
                    <div className="w-full">
                      <label className="block mb-2 text-lg font-bold text-gray-700">
                        Store name
                      </label>
                      <input
                        type="search"
                        className="w-full bg-purple-white shadow-lg text-lg rounded-lg border-none
                          focus:outline-none focus:shadow-outline"
                        placeholder="Filter store by name..."
                        value={searchValue}
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* Cards */}
            <div className="grid grid-cols-2 gap-4">
              {stores.map((key) => (
                <StoreCard key={key} store={key} />
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Stores;
