import React, { useRef, useEffect } from "react";

import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  BubbleController,
} from "chart.js";
import "chartjs-adapter-moment";

// Import utilities
import { tailwindConfig, formatValue } from "../../utils/Utils";
import { focusHandling } from "cruip-js-toolkit";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  BubbleController
);

const AllSizesChart = ({ data, width, height, labelsNumber }) => {
  const canvas = useRef(null);
  const legend = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, {
      type: "bubble",
      data: data,
      options: {
        chartArea: {
          backgroundColor: tailwindConfig().theme.colors.white,
        },
        layout: {
          padding: 20,
          margin: 10,
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              beginAtZero: true,
            },
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => `${value} RON`,
            },
          },
          x: {
            type: "time",
            time: {
              parser: "YYYY-MM-DD",
              unit: "day",
            },
            display: true,
            ticks: {
              maxTicksLimit: 10,
              callback: (value) => value,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              // In case of hourly sales analysis
              title: () => false, // Disable tooltip title
              label: (context) =>
                `${context.parsed.y} RON (sold ${
                  context.parsed._custom / 2
                } for ${
                  (context.parsed._custom / 2) * context.parsed.y
                } RON) on ${
                  new Date(context.parsed.x)
                    .toLocaleString("en-GB")
                    .split(",")[0]
                }`,
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
      },
      plugins: [
        {
          id: "htmlLegend",
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            items.slice(0, labelsNumber).forEach((item) => {
              const li = document.createElement("li");
              li.style.marginLeft = tailwindConfig().theme.margin[3];
              // Button element
              const button = document.createElement("button");
              button.style.display = "inline-flex";
              button.style.alignItems = "center";
              button.style.opacity = item.hidden ? ".3" : "";
              button.onclick = () => {
                c.setDatasetVisibility(
                  item.datasetIndex,
                  !c.isDatasetVisible(item.datasetIndex)
                );
                c.update();
                focusHandling("outline");
              };
              // Color box
              const box = document.createElement("span");
              box.style.display = "block";
              box.style.width = tailwindConfig().theme.width[3];
              box.style.height = tailwindConfig().theme.height[3];
              box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
              box.style.marginRight = tailwindConfig().theme.margin[2];
              box.style.borderWidth = "3px";
              box.style.borderColor =
                c.data.datasets[item.datasetIndex].borderColor;
              box.style.pointerEvents = "none";
              // Label
              const label = document.createElement("span");
              label.style.color = tailwindConfig().theme.colors.gray[500];
              label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
              label.style.lineHeight =
                tailwindConfig().theme.fontSize.sm[1].lineHeight;
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });

    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="px-5 py-3">
        <div className="flex-grow ml-2 mb-1">
          <ul ref={legend} className="flex flex-wrap justify-end" />
        </div>
      </div>
      <div className="flex-grow">
        <canvas ref={canvas} width={width} height={height} />
      </div>
    </>
  );
};

export default AllSizesChart;
