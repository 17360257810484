import React, { useState } from "react";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import GeneralInfo from "../../partials/user-analytics/GeneralInfo";
import Actions from "../../partials/user-analytics/Action";
import DesiredProducts from "../../partials/user-analytics/DesiredProducts";
import ViewedProducts from "../../partials/user-analytics/ViewedProducts";
import Ranking from "../../partials/user-analytics/Ranking";
import ActionsTable from "../../partials/user-analytics/ActionsTable";

const UserAnalytics = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* General user information */}
            <h1 className="font-semibold text-xl p-4">Overview</h1>
            <div className="px-4 py-4">
              <GeneralInfo />
            </div>

            {/* Graph: user activity in last 30 days (sessions / day) */}

            {/* Most common actions*/}
            <h1 className="font-semibold text-xl p-4">Most common actions</h1>
            <div className="px-4 py-4">
              <ActionsTable />
            </div>

            {/* Most common behaviour before sale */}
            <h1 className="font-semibold text-xl p-4">
              Most common behaviour before sale
            </h1>
            <div className="font-semibold p-4 rounded-lg shadow-lg bg-green-200 flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="text-center ml-2">
                Most common actions users make on site before finish placing a
                command.
              </span>
            </div>
            <Actions />

            {/* Products overview */}
            <h1 className="font-semibold text-xl p-4">
              Products overview (desired products)
            </h1>
            <div className="px-4 py-4">
              <DesiredProducts />
            </div>

            <h1 className="font-semibold text-xl p-4">
              Products overview (most viewed products)
            </h1>
            <div className="px-4 py-4">
              <ViewedProducts />
            </div>

            {/* User overview: most performant users and their rank */}
            <h1 className="font-semibold text-xl p-4">Products overview</h1>
            <div className="px-4 py-4">
              <Ranking />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserAnalytics;
