/***
 *  Local storage service.
 */

const TYPE_ERROR = "Key and Value parameters are not string instances";
const SUCCESS = "Operation successful";

/**
 *  Local storage store function. Returns a promise containing the result of writing data to browser local storage.
 *
 * @param key
 * @param value
 * @returns {Promise<string|void>}
 */
const asyncLocalStore = async (key, value) => {
  if (typeof key === "string") {
    await localStorage.setItem(key, value);
    return SUCCESS;
  } else {
    await null;
    return TYPE_ERROR;
  }
};

/**
 * Local storage get function. Returns a promise containing the read data from browser local storage.
 *
 * @param key
 * @returns {Promise<string>}
 */
const asyncLocalGet = async (key) => {
  if (typeof key === "string") {
    await null;
    return localStorage.getItem(key);
  } else {
    await null;
    return TYPE_ERROR;
  }
};

/**
 * Local storage clear function. Returns a promise containing the clear result of browser local storage.
 *
 * @returns {Promise<string>}
 */
const asyncLocalClear = async () => {
  await localStorage.clear();
  return SUCCESS;
};

/**
 * Local storage remove function. Returns a promise containing the result of removing key from browser local storage.
 *
 * @param key
 * @returns {Promise<string|void>}
 */
const asyncLocalRemove = async (key) => {
  if (typeof key === "string") {
    await localStorage.removeItem(key);
    return SUCCESS;
  } else {
    await null;
    return TYPE_ERROR;
  }
};

export {
  asyncLocalStore,
  asyncLocalGet,
  asyncLocalRemove,
  asyncLocalClear,
  SUCCESS,
  TYPE_ERROR,
};
