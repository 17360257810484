import React, { useEffect, useState } from "react";
import { largeNumberFormat } from "../../utils/Utils";
import StoreTarget from "../dashboard/sales_by_store_table/StoreTarget";
import { targetColorsMap } from "./utils/TargetColorsMap";
import axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";
import { API } from "../../services/config";
import { useHistory } from "react-router-dom";
import StoreError from "./error/StoreError";

const TargetInfo = ({ storeName, year, month, shouldUpdate }) => {
  const history = useHistory();
  const [storeTargets, setStoreTargets] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const getStoreTargetData = async () => {
      const jwt = await asyncLocalGet("token");
      try {
        const result = await axios.post(
          API.stores.fetchTarget,
          {
            date: `${year}-${month}-01`,
            location: storeName,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        if (result.status === 200) {
          setStoreTargets(result?.data);
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for product presentation image was canceled due to an error when fetching data.";
        }

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push("/");
          }
        }

        setError("The store targets cannot be loaded!");
        setStoreTargets(null);
      }
    };

    getStoreTargetData().then((r) => r);
    return () => {
      source.cancel("Request for post presentation image was canceled.");
    };
  }, [history, month, storeName, year, shouldUpdate]);

  return (
    <div className="border-none">
      {/* Store name */}
      <div className="top-section flex flex-col sm:flex-row  space-x-5 text-center sm:text-left">
        <h1 className="font-semibold text-lg mb-5">
          {storeName.toUpperCase()}
        </h1>
      </div>
      {storeTargets && (
        <h2 className="font-semibold text-lg mb-5 text-green-500 text-center">
          Revenue: {largeNumberFormat(storeTargets[0]?.current_value, 2)} RON
        </h2>
      )}
      {/* Targets list */}
      <div className="text-gray-600 font-semibold text-justify sm:text-left mb-6">
        <ul className="flex flex-col mr-5 mt-2">
          {storeTargets &&
            storeTargets.map((target) => (
              <li
                className="hover:text-gray-700 w-full"
                key={target?.level_name}
              >
                <StoreTarget
                  targetName={`${target?.level_name} target`}
                  targetProgress={target?.completion_percentage}
                  targetAmount={target?.level_value}
                  bgColor={targetColorsMap[target?.level_name]}
                />
              </li>
            ))}
        </ul>
        {error && <StoreError message={error} />}
      </div>
    </div>
  );
};

export default TargetInfo;
