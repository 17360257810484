import React from "react";
import { useHistory } from "react-router-dom";

const Help = () => {
  const history = useHistory();

  const handleBackNavigation = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="w-full max-w-9xl mx-auto">
            {/* Social filters */}
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
              <div className="max-w-md w-full space-y-8 border rounded-lg bg-white shadow-lg p-10">
                <h1 className="text-center text-blue-500 font-semibold">
                  Forgot password ?
                </h1>
                <p>
                  For any problem regarding the authentication problems please
                  contact the Administrator of the platform. In this moment we
                  are able to send <b>confirmation emails</b> and any other form
                  of <b>password recovery</b>.
                </p>

                <div className="border-none flex justify-center">
                  <button
                    className="px-3 py-2 font-normal flex text-white bg-purple-600 rounded-lg hover:bg-purple-500 focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={(e) => handleBackNavigation(e)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    Back to Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Help;
