/**
 *  API endpoints config file
 *
 */

/**
 * Base api (for DASHBOARD, EXPLORE, SOCIAL MEDIA etc.)
 */
const port = 20001;
const baseUrl = `http://84.239.35.190:${port}`;

const API = {
  status: {
    status: `${baseUrl}/`,
  },
  auth: {
    login: `${baseUrl}/auth/login`,
    register: `${baseUrl}/auth/register`,
    getUsers: `${baseUrl}/auth/get_users`,
    delete: `${baseUrl}/auth/delete`,
  },
  explore: {
    filter: `${baseUrl}/explore/filter`,
    productStock: `${baseUrl}/explore/product_stock`,
    parentStock: `${baseUrl}/explore/parent_stock`,
    productImages: `${baseUrl}/explore/product_images`,
    presentationImages: `${baseUrl}/explore/presentation_images`,
    discountedSales: `${baseUrl}/explore/discounted_sales`,
    productWeeklySales: `${baseUrl}/explore/product_weekly_sales`,
    productInteractions: `${baseUrl}/explore/product_interactions`,
  },
  dashboard: {
    yearStream: `${baseUrl}/dashboard/year_stream`,
    locationTotal: `${baseUrl}/dashboard/location_total`,
    productMetrics: `${baseUrl}/dashboard/product_metrics`,
    bestSellers: `${baseUrl}/dashboard/best_sellers`,
    compareFilteredMetrics: `${baseUrl}/dashboard/compare_filtered_metrics`,
    filteredMetrics: `${baseUrl}/dashboard/filtered_metrics`,
    filterNotFilterMetrics: `${baseUrl}/dashboard/filter_not_filter_metrics`,
    mostViewedProducts: `${baseUrl}/dashboard/most_viewed_products`,
    storeCompletionTarget: `${baseUrl}/dashboard/store_target_completion`,
  },
  stores: {
    updateTarget: `${baseUrl}/stores/update_target`,
    fetchTarget: `${baseUrl}/stores/fetch_target`,
  },
  collection: {
    composition: `${baseUrl}/collection/composition`,
    statistics: `${baseUrl}/collection/statistics`,
  },
  socialMedia: {
    instagram: {
      addAssociatedProducts: `${baseUrl}/social_media/instagram/add_associated_products`,
      delAssociatedProducts: `${baseUrl}/social_media/instagram/del_associated_products`,
      getPosts: `${baseUrl}/social_media/instagram/get_posts`,
      getPostMedia: `${baseUrl}/social_media/instagram/get_post_media`,
      getPostByHash: `${baseUrl}/social_media/instagram/get_post_by_hash`,
    },
    facebook: {
      addAssociatedProducts: `${baseUrl}/social_media/facebook/add_associated_products`,
      delAssociatedProducts: `${baseUrl}/social_media/facebook/del_associated_products`,
      getPosts: `${baseUrl}/social_media/facebook/get_posts`,
      getPostMedia: `${baseUrl}/social_media/facebook/get_post_media`,
      getPostByHash: `${baseUrl}/social_media/facebook/get_post_by_hash`,
    },
  },
  campaigns: {
    analytics: `${baseUrl}/campaigns/google_analytics/google_analytics_front_view`,
    getBannerImage: `${baseUrl}/campaigns/google_analytics/get_banner_image`,
    uploadBannerImage: `${baseUrl}/campaigns/google_analytics/upload_banner_image`,
  },
};

/**
 * Products api (from user behaviour analysis)
 */
const nissaProductsPort = 20014;
const nissaProductsBaseUrl = `http://84.239.35.190:${nissaProductsPort}`;

const productsAPI = {
  products: {
    getDesiredProductsDetails: `${nissaProductsBaseUrl}/desired_products_details`,
    getMostViewedProducts: `${nissaProductsBaseUrl}/viewed_products_details`,
    getActionsDistribution: `${nissaProductsBaseUrl}/get_action_distribution`,
    getMostCommonActionsBeforeSale: `${nissaProductsBaseUrl}/get_most_common_action_before_sale`,
    generalInfo: `${nissaProductsBaseUrl}/get_general_info`,
  },
};

/**
 * Products api (from user behaviour analysis)
 */
const nissaUsersPort = 20013;
const nissaUsersBaseUrl = `http://84.239.35.190:${nissaUsersPort}`;

const userAPI = {
  users: {
    getHighPerformingUsers: `${nissaUsersBaseUrl}/get_high_performing_users`,
    getLowPerformingUsers: `${nissaUsersBaseUrl}/get_low_performing_users`,
    getUserRank: `${nissaUsersBaseUrl}/get_user_rank`,
  },
};

/**
 * Sentiment analysis api
 *
 */
const nissaSentimentPort = 27010;
const nissaSentimentBaseUrl = `http://81.180.242.177:${nissaSentimentPort}`;

const sentimentAPI = {
  sentiment: {
    getSentiment: `${nissaSentimentBaseUrl}/get_sentiment`,
  },
};

export { API, userAPI, productsAPI, sentimentAPI };
