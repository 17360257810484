const StoresMap = {
  1403: "Cluj Polus",
  1405: "Pitesti Jupiter",
  1407: "Bucuresti Cotroceni",
  1409: "Constanta",
  1410: "Craiova",
  1411: "Iasi Palas",
  1413: "Bucuresti Baneasa Mall",
  1414: "Timisoara",
  1417: "Constanta City Park",
  1421: "Bucuresti Mall",
  1424: "Mamaia Nuba",
  1428: "Bucuresti Vitan Mall",
  1429: "Sibiu Promenada",
  1430: "Brasov AFI",
  1431: "Suceava Iulius",
  1416: "online",
  1418: "Coresi Brasov",
  1419: "Bacau",
  1425: "Ploiesti AFI",
  1404: "Bucuresti Cora",
  1408: "Bucuresti Romana",
  1401: "Constanta Tomis",
  1402: "Iasi Felicia",
};

export { StoresMap };
