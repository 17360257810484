import React from "react";

const Loading = () => {
  return (
    <div className="flex justify-center w-full h-10">
      <span className="flex h-0 w-0">
        <span className="animate-bounce absolute inline-flex h-5 w-5 rounded-full bg-purple-500 opacity-75" />
        <span className="relative inline-flex rounded-full h-5 w-5 bg-purple-500" />
      </span>
    </div>
  );
};

export default Loading;
