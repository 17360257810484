import React from "react";
import { useHistory } from "react-router-dom";
import {
  asyncLocalClear,
  asyncLocalRemove,
} from "../../services/storage/local_storage";

const UserMenu = () => {
  const history = useHistory();

  const signOutHandler = (e) => {
    e.preventDefault();
    /* Remove token and clear local storage */
    asyncLocalRemove("token").then((r) => r);
    asyncLocalClear().then((r) => r);

    /* Redirect user to Login page */
    history.push("/");
  };
  return (
    <div className="relative inline-flex">
      <div className="flex items-center truncate">
        <div className="border-none m-6">
          <div className="inline-flex">
            <button
              className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg m-2"
              onClick={(e) => signOutHandler(e)}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
