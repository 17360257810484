import React, { useEffect, useState, useContext } from "react";
import Header from "../../partials/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../partials/Sidebar";
import { randomKey } from "../../utils/Utils";
import { asyncLocalGet } from "../../services/storage/local_storage";
import SocialPostInformation from "../../partials/social/social-product-interaction/SocialPostInformation";
import { API } from "../../services/config";
import SocialProduct from "../../partials/social/social-product-interaction/SocialProduct";
import SocialErrorBanner from "../../partials/social/SocialErrorBanner";
import { SocialProductTypes } from "../../partials/social/social-product-interaction/SocialProductTypes";
import { SocialMediaProductsContext } from "../../contexts/SocialMediaProductsContext";

const SocialProductInteraction = () => {
  const { selectedProductsForAdd, selectedProductsForDel } = useContext(
    SocialMediaProductsContext
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();

  const [post, setPost] = useState(null);
  const [image, setImage] = useState(null);
  const [source, setSource] = useState(null);

  /* Store the has in order to update the entire page after changes have been saved */
  const [hash, setHash] = useState(null);

  /* Products search */
  const [productType, setProductType] = useState("");
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    /* Navigation state */
    setPost(history.location.state.post);
    setImage(history.location.state.image);
    setSource(history.location.state.from);
    setHash(history.location.state.post?.hash);
  }, [history]);

  const handleBackNavigation = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleProductTypeOnChange = (e) => {
    setProductType(e.target.value);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const performSearch = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {
        filter: {
          product_type: productType.toUpperCase(),
        },
        start: 0,
        end: 50,
        reset: true,
      };

      try {
        const result = await axios.post(API.explore.filter, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 200) {
          // The parent code is a valid one
          if (Object.keys(result?.data).length !== 0) {
            setProducts(Object.entries(result?.data));
            setError(null);
          } else {
            setError("There are no matches.");
            setProducts(null);
          }
        }
      } catch (error) {
        setError(error.message);
        setProducts(null);
      }
    };

    performSearch().then((r) => r);
  };

  const saveChangesHandler = (e) => {
    e.preventDefault();

    const getProduct = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {};
      data.media_hash = hash;
      console.log(data);
      try {
        const result = await axios.post(
          source === "facebook"
            ? API.socialMedia.facebook.getPostByHash
            : API.socialMedia.instagram.getPostByHash,
          data,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        if (result.status === 200) {
          setPost(result?.data);
          setError(null);
        }
      } catch (error) {
        setError("The product cannot be updated!");
      }
    };

    const updateAssociatedProductsAdd = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {
        media_hash: post?.hash,
        parent_code: selectedProductsForAdd.map((p) => p.parentCode),
      };
      console.log(data);
      try {
        const url =
          source === "facebook"
            ? API.socialMedia.facebook.addAssociatedProducts
            : API.socialMedia.instagram.addAssociatedProducts;
        const result = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 200) {
          // The parent code is a valid one
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const updateAssociatedProductsDel = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {
        media_hash: post?.hash,
        parent_code: selectedProductsForDel.map((p) => p.parentCode),
      };
      console.log(data);
      try {
        const url =
          source === "facebook"
            ? API.socialMedia.facebook.delAssociatedProducts
            : API.socialMedia.instagram.delAssociatedProducts;
        const result = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 200) {
          // The parent code is a valid one
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    /* Call if needed one of the methods */
    if (selectedProductsForAdd.length !== 0)
      updateAssociatedProductsAdd().then((r) => r);

    if (selectedProductsForDel.length !== 0)
      updateAssociatedProductsDel().then((r) => r);

    getProduct().then((r) => r);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Back navigation */}
        <div className="border-none m-6 flex justify-start">
          <button
            className="px-3 py-2 font-semibold flex text-white bg-purple-600 rounded-lg hover:bg-purple-500 focus:outline-none focus:shadow-outline mr-10"
            type="button"
            onClick={(e) => handleBackNavigation(e)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back
          </button>
          <button
            className="px-3 py-2 font-semibold flex text-white bg-purple-600 rounded-lg hover:bg-purple-500 focus:outline-none focus:shadow-outline mr-10"
            type="button"
            onClick={(e) => saveChangesHandler(e)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
              />
            </svg>
            Save changes
          </button>
        </div>

        {/* Social interactions for current product list */}
        <div className="flex justify-center mb-10 bg-gray-50 rounded-lg shadow-lg m-10">
          <div className="p-10">
            {/* Social post list */}
            {image && (
              <img
                src={`data:image/jpeg;base64,${image.toString("base64")}`}
                className="rounded-lg shadow-lg object-cover h-96"
                key={randomKey(8, 3)}
              />
            )}
            {post && (
              <p className="font-semibold p-2 text-center mt-10 rounded-lg shadow-lg bg-blue-100">
                {post?.captions}
              </p>
            )}
          </div>
        </div>

        <main>
          {/* General post info */}
          {post && (
            <SocialPostInformation
              generalSentiment={post?.sentiment}
              numComments={post?.comments.length}
              postDate={`${post?.post_creation_date.split("T")[0]}`}
              numInteractions={Object.values(post?.interactions).reduce(
                (e0, e1) => parseInt(e0) + parseInt(e1)
              )}
            />
          )}

          {/* Associated products */}
          {post && post?.associated_products && (
            <div className="border-none bg-white rounded-lg shadow-lg m-10 p-10">
              <div className="grid grid-cols-10 gap-6">
                {post?.associated_products.map((pd) => (
                  <SocialProduct
                    parentCode={pd}
                    key={pd}
                    type={SocialProductTypes.associated}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Search form */}
          <div className="border-none m-20 bg-gray-50 rounded-lg shadow-lg">
            <form className="border-b border-gray-200">
              <div className="mb-6 flex justify-center w-full">
                <div className="w-5/6">
                  <label className="block mb-2 text-lg font-semibold text-gray-700 mt-10">
                    Identify the product by product type
                  </label>
                  <input
                    type="search"
                    className="w-full bg-purple-white shadow-lg text-lg rounded-lg border-none
                          focus:outline-none focus:shadow-outline p-3"
                    placeholder="Product type"
                    value={productType.toUpperCase()}
                    onChange={(e) => handleProductTypeOnChange(e)}
                  />
                  <div className="border-none mt-6">
                    <button
                      className="px-3 py-2 font-normal flex text-white bg-purple-600 rounded-lg hover:bg-purple-500 focus:outline-none focus:shadow-outline"
                      type="submit"
                      onClick={(e) => searchHandler(e)}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Search results */}
          <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2 flex w-full h-1/2">
            {products && (
              <div className="grid grid-cols-3 gap-6 p-10">
                {products.map((pd) => (
                  <SocialProduct
                    parentCode={pd[0]}
                    key={pd[0]}
                    type={SocialProductTypes.newProduct}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="border-none flex justify-center w-full top-0">
            {error && <SocialErrorBanner message={error} />}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SocialProductInteraction;
