import React from "react";

const CampaignsFetchError = ({ message }) => {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-10 text-center"
      role="alert"
    >
      <strong className="font-bold">Data cannot be loaded.</strong>
      <br />
      <span className="text-sm text-black">Error message: {message} </span>
    </div>
  );
};

export default CampaignsFetchError;
