import React, { useState, useEffect } from "react";
import axios from "axios";
import { userAPI } from "../../services/config";
import { largeNumberFormat } from "../../utils/Utils";

const RankingMap = {
  0: "Aspirational",
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
};

const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );
};

const User = ({ userId, amount }) => {
  const [rank, setRank] = useState("-");
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getUserRank = async () => {
      let data = {
        query: userId,
      };

      try {
        const result = await axios.post(userAPI.users.getUserRank, data, {
          cancelToken: source.token,
        });

        if (result.status === 200) {
          setRank(result.data[0][parseInt(userId)]);
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setRank(null);
        setError(error.message);
      }
    };

    getUserRank().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [userId]);

  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="border-none font-semibold text-xl">{userId}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-semibold text-green-500 text-lg">
            {largeNumberFormat(amount, 2)} RON
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-semibold text-lg">
            {rank && RankingMap[rank?.value_rank]}
          </div>
        </div>
      </td>
    </tr>
  );
};

const Ranking = () => {
  const [usersData, setUsersData] = useState(null);
  const [queryLimit, setQueryLimit] = useState(10);
  const [error, setError] = useState(null);
  const [highPerformant, setHighPerformant] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getSalesData = async () => {
      let data = {
        max_results: queryLimit,
      };

      try {
        const result = await axios.post(
          highPerformant
            ? userAPI.users.getHighPerformingUsers
            : userAPI.users.getLowPerformingUsers,
          data,
          {
            cancelToken: source.token,
          }
        );

        if (result.status === 200) {
          setUsersData(
            Object.entries(result?.data).sort(
              (ue1, ue2) => parseFloat(ue2[1]) - parseFloat(ue1[1])
            )
          );
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setUsersData(null);
        setError(error.message);
      }
    };

    getSalesData().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [queryLimit, highPerformant]);

  const handleQueryLimitOnChange = (e) => {
    setQueryLimit(e.target.value);
  };

  const handleHighPerformantChange = (e) => {
    e.preventDefault();
    setHighPerformant(!highPerformant);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200">
      <header className="px-5 py-4 border-b border top-16 z-40 bg-gray-50">
        <div className="flex flex-row justify-between">
          <h2 className="font-semibold text-gray-800 mt-2 text-lg">
            Users ranking
          </h2>
          <button
            className="flex items-center"
            onClick={(e) => handleHighPerformantChange(e)}
          >
            {highPerformant ? (
              <>
                <span className="font-semibold mr-2 text-green-500">
                  High performant user
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </>
            ) : (
              <>
                <span className="font-semibold mr-2 text-red-400">
                  Low performant user
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
                  />
                </svg>
              </>
            )}
          </button>
          <div className="p-3 border-none rounded-xl">
            <label className="p-0 font-semibold"> Number of users: </label>
            <select
              className="border-none appearance-none pt-0 pb-0 font-semibold text-sm bg-transparent"
              defaultValue={queryLimit}
              onChange={(e) => handleQueryLimitOnChange(e)}
            >
              {[10, 20, 30, 50, 100, 150, 200, 250].map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">User id</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Amount spent</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Ranking</div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {usersData &&
                usersData.map((ue) => (
                  <User userId={ue[0]} amount={ue[1]} key={ue[0]} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Ranking;
