const LabelsAndColorsMap = {
  DEFAULT: "#77a3c1",

  RZ: "#f43f5e",
  RS: "#ec4899",
  RC: "#9e0db4",

  TOP: "#657bfc",
  TOPS: "#8ba0ff",
  BODY: "#4d64ff",

  FS: "#a51849",
  FM: "#800828",

  BO: "#b8aa09",
  PA: "#dbd16d",
  CER: "#72ef80",
  COL: "#f43f5e",
  CB: "#ec4899",
  CU: "#9e0db4",

  S: "#465def",
  C: "#96a8e3",
  J: "#6c76f3",
  P: "#46dbef",
  F: "#12c3e5",

  SALOS: "#f5c640",
  SALO: "#ffc98c",
};

export { LabelsAndColorsMap };
