import React, { useState, useEffect } from "react";
import {
  asyncLocalGet,
  asyncLocalStore,
} from "../../../services/storage/local_storage";

const InstagramFilterForm = ({ onSearch }) => {
  const [commentString, setCommentString] = useState("");
  const [hashTagsString, setHashTagsString] = useState("");
  const [exactTags, setExactTags] = useState(false);
  const [order, setOrder] = useState("dsc");

  useEffect(() => {
    let ignore = false;
    /* Local storage data retrieval */
    const populate = async () => {
      if (!ignore) {
        setHashTagsString((await asyncLocalGet("ig_hashtag_string")) || []);
        setExactTags((await asyncLocalGet("ig_exact_tags")) === "yes" || false);
        setCommentString((await asyncLocalGet("ig_comment_string")) || "");
      }
    };

    populate().then((r) => r);
    return () => {
      ignore = true;
    };
  }, []);

  const onHashTagsStringChange = (e) => {
    setHashTagsString(e.target.value);
    asyncLocalStore("ig_hashtag_string", e.target.value).then((r) => r);
  };

  const onCommentStringChange = (e) => {
    setCommentString(e.target.value);
    asyncLocalStore("ig_comment_string", e.target.value).then((r) => r);
  };

  const onExactTagsChange = () => {
    setExactTags(!exactTags);
    if (exactTags) asyncLocalStore("ig_exact_tags", "no").then((r) => r);
    else asyncLocalStore("ig_exact_tags", "yes").then((r) => r);
  };

  const onOrderChange = (e) => {
    setOrder(e.target.value);
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    onSearch(e, hashTagsString, commentString, exactTags, order);
  };

  return (
    <form className="px-8 pt-0 pb-8 mb-4 rounded">
      <h3 className="pt-4 text-4xl text-left font-bold mb-10">
        Instagram posts
      </h3>
      <div className="border-none">
        <h1 className="text-3xl font-semibold mb-2">Text filters</h1>
        <hr className="h-4" />
        {/* HASHTAGS */}
        <div className="mb-6 flex justify-center w-full">
          <div className="w-5/6">
            <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
              Hashtags
            </label>
            <label className="block mb-2 text-xs font-normal text-gray-700 ml-3">
              Search for hashtags with space between them
            </label>
            <input
              type="search"
              className="w-full bg-purple-white shadow-lg text-lg rounded-lg border-none
                          focus:outline-none focus:shadow-outline p-3"
              placeholder="Filter Facebook posts by hashtag..."
              onChange={(e) => onHashTagsStringChange(e)}
              value={hashTagsString}
            />
          </div>
          <div className="border-none ml-10 mt-12 w-1/6">
            <label className="inline-flex items-center m-5">
              <span className="mr-2">Exact match</span>
              <input
                type="checkbox"
                className="form-checkbox h-8 w-8 px-3 py-2 mt-0 text-xl leading-tight text-gray-700
                            border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                onChange={() => onExactTagsChange()}
                checked={exactTags}
              />
            </label>
          </div>
        </div>
        <hr className="h-4" />
        {/* COMMENTS */}
        <div className="mb-6 flex justify-center w-full">
          <div className="w-full">
            <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
              Comments
            </label>
            <label className="block mb-2 text-xs font-normal text-gray-700 ml-3">
              Search for specific comments of an Instagram post
            </label>
            <input
              type="search"
              className="w-full bg-purple-white shadow-lg text-lg rounded-lg border-none
                          focus:outline-none focus:shadow-outline p-3"
              placeholder="Filter Facebook posts by comments..."
              value={commentString}
              onChange={(e) => onCommentStringChange(e)}
            />
          </div>
        </div>
        <hr className="h-4" />
        <div className="mb-6">
          <label className="p-2 font-semibold"> Order: </label>
          <select
            className="border-none appearance-none pt-0 pb-0 font-semibold bg-gray-50"
            onChange={(e) => onOrderChange(e)}
          >
            <option value="dsc">Most engagement first</option>
            <option value="asc">Least engagement first</option>
          </select>
        </div>

        <div className="mb-6 md:flex md:justify-start">
          <button
            className="px-3 py-2 font-normal text-white bg-purple-600 rounded-lg hover:bg-purple-500
                    focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={(e) => onSearchHandler(e)}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default InstagramFilterForm;
