import React, { useState, useEffect } from "react";
import { productsAPI } from "../../services/config";
import * as axios from "axios";

const ActionInfo = ({ actionId, actionCount, actionProgress, actionName }) => {
  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="border-none font-semibold text-xl">{actionId}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-semibold text-blue-500 text-lg">
            {actionName}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="border-none font-semibold text-xl">{actionCount}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="border-none font-semibold text-xl text-green-400">
            {parseFloat(actionProgress).toFixed(2)} %
          </div>
        </div>
      </td>
    </tr>
  );
};

const ActionsTable = () => {
  const [actions, setActions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getActions = async () => {
      try {
        const result = await axios.post(
          productsAPI.products.getActionsDistribution,
          {},
          {
            cancelToken: source.token,
          }
        );

        if (result.status === 200) {
          setActions(
            Object.entries(result?.data).sort(
              (ae1, ae2) =>
                parseFloat(ae2[1]?.change) - parseFloat(ae1[1]?.change)
            )
          );
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setActions(null);
        setError(error.message);
      }
    };

    getActions().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200">
      <header className="px-5 py-4 border-b border top-16 z-40 bg-gray-50">
        <div className="flex flex-row justify-between">
          <h2 className="font-semibold text-gray-800 mt-2 text-lg">
            Most common actions
          </h2>
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Action id</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Action name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Action count</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">
                    Action progress
                  </div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {actions &&
                actions.map((ae) => (
                  <ActionInfo
                    actionId={ae[0]}
                    actionCount={ae[1]?.value}
                    actionProgress={ae[1]?.change}
                    actionName={ae[1]?.name}
                    key={ae[0]}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActionsTable;
