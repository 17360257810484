import React, { useState, useEffect } from "react";
import SalesDonutTableRow from "./SalesDonutTableRow";

const SalesDonutTable = ({ labels, stats, colors, topN }) => {
  const [bestSellersData, setBestSellersData] = useState([]);

  useEffect(() => {
    let constructTableData = [];
    [...Array(topN).keys()].map((idx) =>
      constructTableData.push({
        stats: stats[idx],
        color: colors[idx],
        label: labels[idx],
      })
    );
    setBestSellersData(constructTableData);
  }, [labels, stats, colors, topN]);

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200">
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          {bestSellersData && (
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Color</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Product Type
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Sold Quantity
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Total Quantity
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Revenue (RON)
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Expected Revenue (RON)
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Price Performance
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center">
                      Stock Performance
                    </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}

              <tbody className="text-sm divide-y divide-gray-100">
                {bestSellersData.map((product) => (
                  <SalesDonutTableRow
                    product={product}
                    key={product.color.toString() + product.label.toString()}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesDonutTable;
