import React from "react";

const SocialPostInformation = ({
  numInteractions,
  postDate,
  numComments,
  generalSentiment,
}) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Product information */}
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-6 md:col-span-3 rounded-lg shadow-lg">
          <div className="flex flex-row bg-white shadow-sm rounded p-4">
            <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-100 text-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>
            <div className="flex flex-col flex-grow ml-4">
              <div className="text-sm text-gray-500 font-semibold">
                Interactions
              </div>
              <div className="font-bold text-lg">{numInteractions}</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3 rounded-lg shadow-lg">
          <div className="flex flex-row bg-white shadow-sm rounded p-4">
            <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-100 text-green-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div className="flex flex-col flex-grow ml-4">
              <div className="text-sm text-gray-500 font-semibold">
                Post date
              </div>
              <div className="font-bold text-lg">{postDate}</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3 shadow-lg rounded-lg">
          <div className="flex flex-row bg-white shadow-sm rounded p-4">
            <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-orange-100 text-orange-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
            </div>
            <div className="flex flex-col flex-grow ml-4">
              <div className="text-sm text-gray-500 font-semibold">
                Comments
              </div>
              <div className="font-bold text-lg">{numComments}</div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3 rounded-lg shadow-lg">
          <div className="flex flex-row bg-white shadow-sm rounded p-4">
            <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-red-100 text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div className="flex flex-col flex-grow ml-4">
              <div className="text-sm text-gray-500 font-semibold">
                Sentiment
              </div>
              <div className="font-bold text-lg">{generalSentiment}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialPostInformation;
