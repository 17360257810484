import React, { useState, useEffect } from "react";
import axios from "axios";
import { productsAPI } from "../../services/config";

const ViewedProduct = ({ productId, productDescription }) => {
  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="border-none font-semibold text-xl">{productId}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-semibold text-gray-800 text-lg">
            {productDescription}
          </div>
        </div>
      </td>
    </tr>
  );
};

const ViewedProducts = () => {
  const [productsData, setProductsData] = useState(null);
  const [queryLimit, setQueryLimit] = useState(10);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getSalesData = async () => {
      let data = {
        max_results: queryLimit,
      };

      try {
        const result = await axios.post(
          productsAPI.products.getMostViewedProducts,
          data,
          {
            cancelToken: source.token,
          }
        );

        if (result.status === 200) {
          setProductsData(result?.data);
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setProductsData(null);
        setError(error.message);
      }
    };

    getSalesData().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [queryLimit]);

  const handleQueryLimitOnChange = (e) => {
    setQueryLimit(e.target.value);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200">
      <header className="px-5 py-4 border-b border top-16 z-40 bg-gray-50">
        <div className="flex flex-row justify-between">
          <h2 className="font-semibold text-gray-800 mt-2 text-lg">
            Most viewed products
          </h2>
          <div className="p-3 border-none rounded-xl">
            <label className="p-0 font-semibold"> Number of items: </label>
            <select
              className="border-none appearance-none pt-0 pb-0 font-semibold text-sm bg-transparent"
              defaultValue={queryLimit}
              onChange={(e) => handleQueryLimitOnChange(e)}
            >
              {[10, 20, 30, 50, 100].map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Product id</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    Product description
                  </div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {productsData &&
                Object.entries(productsData).map((pe) => (
                  <ViewedProduct
                    productId={pe[0]}
                    productDescription={pe[1]}
                    key={pe[0]}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewedProducts;
