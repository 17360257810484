import React, { useState, useEffect, lazy, Suspense } from "react";
import { asyncLocalGet } from "../../../services/storage/local_storage";
import axios from "axios";
import { API } from "../../../services/config";
import { useHistory } from "react-router-dom";
import Datepicker from "../../actions/Datepicker";
import { getDateString, largeNumberFormat } from "../../../utils/Utils";
import PingCircle from "../../animations/PingCircle";
import Loading from "../../animations/Loading";
import CampaignsFetchError from "../error/CampaignsFetchError";

const AnalyticsCampaignTableRow = lazy(() =>
  import("./AnalyticsCampaignTableRow")
);

const AnalyticsCampaignTable = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [interval, setInterval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  /* Default values for analytics query (start date = today, end date = 7 days ago)*/
  const endDate = new Date().toISOString().split("T")[0];
  const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];

  useEffect(() => {
    const getAnalyticsData = async () => {
      const jwt = await asyncLocalGet("token");
      let data = {};

      data.start_date = startDate;
      data.end_date = endDate;

      if (interval) {
        data.start_date = getDateString(interval[0]);
        data.end_date = getDateString(interval[1]);
      }

      try {
        const result = await axios.post(API.campaigns.analytics, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 200) {
          setAnalyticsData(result?.data?.reports[0]?.data);
          setError(null);
          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push("/");
          }
        }
        setError(error.message);
        setLoading(false);
      }
    };

    getAnalyticsData().then((r) => r);
  }, [startDate, endDate, history, interval]);

  const handleDatepickerSelect = (_interval) => {
    setInterval(_interval);
    setLoading(true);
    setAnalyticsData(null);
  };

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200">
      <header className="px-5 py-4 border-b border sticky top-16 z-30 bg-gray-50">
        <div className="flex flex-row justify-between">
          <h2 className="font-semibold text-gray-800 mt-2 text-lg">
            <span className="text-blue-500"> Google Analytics </span> Campaigns
            {analyticsData && (
              <span className="text-green-500 ml-10">
                Found: {analyticsData?.rows.length} results
              </span>
            )}
          </h2>
          {/* Time picker */}
          <div className="mb-6 flex text-sm">
            <div className="p-3 border-none rounded-xl flex justify-center">
              {/* TODO: Resolve this */}
              <Datepicker
                visible={true}
                datePickerHandler={handleDatepickerSelect}
              />
            </div>
          </div>
        </div>
      </header>
      {analyticsData && (
        <div className="p-3">
          <div className="overflow-x-auto rounded-lg shadow-lg">
            <table className="table-auto w-full rounded-lg shadow-lg">
              {/* Table header */}
              <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-right">
                      Campaign image
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Campaign name</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      New users
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {largeNumberFormat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[0],
                            2
                          )}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      New Sessions
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {parseFloat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[1]
                          ).toFixed(2)}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      Sessions
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {largeNumberFormat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[2],
                            2
                          )}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      Avg. Session Duration
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {largeNumberFormat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[3],
                            2
                          )}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      Page Views/Session
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {largeNumberFormat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[4],
                            2
                          )}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left flex flex-col">
                      Bounce rate
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {parseFloat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[5]
                          ).toFixed(2)}{" "}
                          % )
                        </span>
                      )}
                    </div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-center flex flex-col">
                      Revenue
                      {analyticsData && (
                        <span className="text-black m-2">
                          (
                          {largeNumberFormat(
                            analyticsData?.rows.filter(
                              (e) => e?.dimensions[0] === "(not set)"
                            )[0]?.metrics[0]?.values[6],
                            2
                          )}
                          )
                        </span>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <Suspense
                fallback={
                  <tbody>
                    <tr>
                      <td className="p-10 whitespace-nowrap col-span-5 row-span-3">
                        <PingCircle />
                      </td>
                    </tr>
                  </tbody>
                }
              >
                {/* Table body */}
                <tbody className="text-sm divide-y divide-gray-100">
                  {analyticsData?.rows
                    .filter((e) => e?.dimensions[0] !== "(not set)")
                    .map((rowData) => (
                      <AnalyticsCampaignTableRow
                        key={rowData?.dimensions[0]}
                        rowData={[
                          rowData?.dimensions[0],
                          ...rowData?.metrics[0]?.values,
                        ]}
                      />
                    ))}
                </tbody>
              </Suspense>
            </table>
          </div>
        </div>
      )}

      {/* Loading */}
      {loading && (
        <div className="mt-10">
          <Loading />
        </div>
      )}

      {/* Error */}
      {error && (
        <div className="mt-10">
          <CampaignsFetchError message={error} />
        </div>
      )}
    </div>
  );
};

export default AnalyticsCampaignTable;
