import * as generator from "generate-password";

const Generator = (password_length) => {
	if (password_length > 0) {
		return generator.generate({
			length: password_length,
			uppercase: true,
			lowercase: true,
			numbers: true,
		});
	} else {
		return Error(
			"The password_length paramter should be a positive integer!"
		);
	}
};

export default Generator;
