import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./css/style.scss";
import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";
import { SocialMediaProductsContextProvider } from "./contexts/SocialMediaProductsContext";

// Import pages
import Dashboard from "./pages/dashboard/Dashboard";
import Explore from "./pages/explore/Explore";
import Development from "./pages/custom/Development";
import Inaccessible from "./pages/custom/Inaccessible";
import Login from "./pages/login/Login";
import SocialFb from "./pages/social-media/SocialFb";
import SocialIg from "./pages/social-media/SocialIg";
import GuardedRoute from "./utils/GuardedRoute";
import ChildProducts from "./pages/child-products/ChildProducts";
import Collections from "./pages/collections/Collections";
import Stores from "./pages/stores/Stores";
import Campaigns from "./pages/campaigns/Campaigns";
import Help from "./pages/custom/Help";
import UserAnalytics from "./pages/users/UserAnalytics";
import SocialProductInteraction from "./pages/social-media/SocialProductInteraction";
import Splash from "./pages/login/Splash";
//import NoPIMProducts from "./pages/no-pim-products/NoPIMProducts";
import Settings from "./pages/custom/Settings";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      {/* Routes */}
      <Switch>
        {/* Splash */}
        <Route exact path="/">
          <Splash />
        </Route>

        {/* Login */}
        <Route exact path="/login">
          <Login />
        </Route>

        {/* Dashboard */}
        <GuardedRoute exact path="/dashboard" component={Dashboard} />

        {/* Explore */}
        <GuardedRoute exact path="/explore" component={Explore} />

        {/* Social */}
        <GuardedRoute exact path="/social" component={SocialFb} />

        {/* Social */}
        <GuardedRoute exact path="/social-fb" component={SocialFb} />

        {/* Social */}
        <GuardedRoute exact path="/social-ig" component={SocialIg} />

        <GuardedRoute exact path="/collections" component={Collections} />

        {/* Campaigns */}
        <GuardedRoute exact path="/campaigns" component={Campaigns} />

        {/* Campaigns */}
        <Route exact path="/user_analytics">
          <UserAnalytics />
        </Route>

        {/* Help (forgot password) */}
        <Route exact path="/help">
          <Help />
        </Route>
        {/* Tasks */}
        <Route exact path="/tasks">
          <Inaccessible />
        </Route>

        {/* Stores */}
        <GuardedRoute exact path="/stores" component={Stores} />

        {/* Settings */}
        <GuardedRoute exact path="/settings" component={Settings} />

        {/* No PIM products */}

        {/* -- Anonymous pages -- */}

        {/* Child products */}
        <GuardedRoute exact path="/child_products" component={ChildProducts} />

        {/* Social product interactions */}
        <SocialMediaProductsContextProvider>
          <GuardedRoute
            exact
            path="/social-product"
            component={SocialProductInteraction}
          />
        </SocialMediaProductsContextProvider>
      </Switch>
    </>
  );
};

export default App;
