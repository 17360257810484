import React, { useEffect, useState } from "react";
import { constructColors, tailwindConfig } from "../../../utils/Utils";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { asyncLocalGet } from "../../../services/storage/local_storage";
import CollectionSalesDonut from "./CollectionSalesDonut";
import SalesDonutTable from "./SalesDonutTable";
import CollectionError from "../erorr/CollectionError";
import { API } from "../../../services/config";

const CollectionSalesDonutCard = ({ collectionID }) => {
  const history = useHistory();
  const [productNames, setProductNames] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productStats, setProductStats] = useState([]);
  const [colorArray, setColorArray] = useState([]);

  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getComposition = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {};

      if (collectionID !== "") {
        data.collection = parseInt(collectionID);
      }

      try {
        const result = await axios.post(API.collection.statistics, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 200) {
          let labels = Object.keys(result?.data);
          setProductNames(labels);

          let colors = constructColors(labels);
          setColorArray(colors);

          setProductQuantity(
            Object.entries(result?.data).map((e) => e[1]["sales_quantity"])
          );
          setProductStats(Object.values(result?.data));

          setError(null);
        }
      } catch (error) {
        setError(error);
        setProductNames([]);
        setProductQuantity([]);
        setProductStats([]);

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        }

        if (axios.isCancel(error)) {
          return "Request for was canceled due to an error when fetching data.";
        }
      }
    };

    getComposition().then((r) => r);
    return () => {
      source.cancel("Request was canceled.");
    };
  }, [collectionID, history]);

  const chartData = {
    labels: productNames,
    datasets: [
      {
        label: "Sales by store pie",
        data: productQuantity,
        backgroundColor: colorArray,
        hoverBackgroundColor: [tailwindConfig().theme.colors.indigo[600]],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  return (
    <div>
      {error && (
        <div className="flex justify-center text-center">
          <CollectionError message={"Could not load data!"} />
        </div>
      )}
      {productNames.length > 0 &&
        productQuantity.length > 0 &&
        productStats.length > 0 && (
          <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-lg border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">
                Sales by product labels
              </h2>
            </header>
            {/* Chart built with Chart.js 3 */}
            <div className="grid grid-cols-3">
              {/* Chart built with Chart.js 3 */}
              <div className="mt-20">
                <CollectionSalesDonut
                  data={chartData}
                  width={400}
                  height={300}
                />
              </div>

              <div className="p-5 w-full col-span-2">
                <SalesDonutTable
                  colors={colorArray}
                  labels={productNames}
                  stats={productStats}
                  topN={10}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default CollectionSalesDonutCard;
