import React, { useState } from "react";

const CollectionFilterForm = ({ onSearch }) => {
  const [collectionID, setCollectionID] = useState("");

  const onCollectionIDChange = (e) => {
    setCollectionID(e.target.value);
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    onSearch(e, collectionID);
  };

  return (
    <form className="px-8 pt-0 pb-8 mb-4 rounded">
      <h3 className="pt-4 text-4xl text-left font-bold mb-10">
        Collection filter
      </h3>
      <div className="border-none">
        <h1 className="text-3xl font-semibold mb-2">ID filter</h1>
        <hr className="h-4" />
        {/* Hashtags */}
        <div className="mb-6 flex justify-center w-full">
          <div className="w-full">
            <label className="block mb-2 text-lg font-bold text-gray-700">
              Collection ID
            </label>
            <label className="block mb-2 text-xs font-normal text-gray-700">
              Search for a collection by ID
            </label>
            <input
              type="search"
              className="w-full bg-purple-white shadow-lg text-lg rounded-lg border-none
                          focus:outline-none focus:shadow-outline"
              placeholder="Filter by collection ID..."
              onChange={(e) => onCollectionIDChange(e)}
              value={collectionID}
            />
          </div>
        </div>
      </div>
      <div className="mb-6 md:flex md:justify-start">
        <button
          className="px-3 py-2 font-normal text-white bg-purple-600 rounded-lg hover:bg-purple-500
                    focus:outline-none focus:shadow-outline"
          type="submit"
          onClick={(e) => onSearchHandler(e)}
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default CollectionFilterForm;
