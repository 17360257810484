import React, { useState, lazy, Suspense, useEffect } from "react";
import Header from "../../partials/Header";
import { useHistory } from "react-router-dom";
import SearchBounce from "../../partials/animations/SearchBounce";
import Sidebar from "../../partials/Sidebar";
import axios from "axios";
import {
  asyncLocalGet,
  asyncLocalStore,
} from "../../services/storage/local_storage";
import FacebookFilterForm from "../../partials/social/social-fb/FacebookFilterForm";
import { API } from "../../services/config";
import SocialErrorBanner from "../../partials/social/SocialErrorBanner";

const FacebookPost = lazy(() =>
  import("../../partials/social/social-fb/FacebookPost")
);

const SocialFb = () => {
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  /* Post data */
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;
    /* Local storage data retrieval */
    const populate = async () => {
      if (!ignore) {
        setPosts(JSON.parse(await asyncLocalGet("fb_posts")) || []);
      }
    };

    populate().then((r) => r);
    return () => {
      ignore = true;
    };
  }, []);

  /* Search button handler */
  const searchHandler = (
    e,
    hashTagsString,
    commentString,
    exactTags,
    order
  ) => {
    e.preventDefault();
    const performSearch = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {};

      if (hashTagsString !== "") {
        data.hashtag_tokens = hashTagsString.split(" ");
      }
      if (commentString !== "") {
        data.text_search = commentString;
      }
      if (exactTags) {
        data.exact_tags = exactTags;
      }

      data.order = order;

      try {
        const result = await axios.post(
          API.socialMedia.facebook.getPosts,
          data,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (result.status === 401 || result.status === 403) {
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
        if (result.status === 200) {
          setPosts(result?.data);
          await asyncLocalStore("fb_posts", JSON.stringify(result?.data));
          setError(null);
        }
      } catch (error) {
        setError(error.message);
        setPosts(null);
      }
    };

    performSearch().then((r) => r);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="w-full bg-white p-5 rounded-lg shadow-lg bg-gray-50 mb-10">
              <FacebookFilterForm onSearch={searchHandler} />
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {posts && (
                <Suspense
                  fallback={
                    <div className="w-full flex justify-center">
                      <SearchBounce />
                    </div>
                  }
                >
                  {posts.map((post) => (
                    <FacebookPost post={post} key={post.hash} />
                  ))}
                </Suspense>
              )}
            </div>
            {error && <SocialErrorBanner message={error} />}
            {posts && posts.length === 0 && (
              <SocialErrorBanner message={"There are no posts!"} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SocialFb;
