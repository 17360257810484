import React, { useState, useEffect } from "react";
import * as axios from "axios";
import { productsAPI } from "../../services/config";

const UpSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
      />
    </svg>
  );
};

const DownSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
      />
    </svg>
  );
};

const Action = ({ actionName, actionProgress, actionId }) => {
  return (
    <div className="flex flex-col justify-center px-4 py-4 bg-white border border-gray-300 rounded-lg shadow-lg bg-blue-100">
      <div>
        <div>
          <p
            className={`flex items-center justify-end ${
              actionProgress > 0 ? "text-green-500" : "text-red-500"
            } text-md`}
          >
            <span className="font-semibold text-md">
              {parseFloat(actionProgress).toFixed(2)}%
            </span>
            {actionProgress > 0 ? <UpSVG /> : <DownSVG />}
          </p>
        </div>
        <p className="text-xl font-semibold text-center text-indigo-500">
          {actionName}
        </p>
        <p className="text-lg text-center text-gray-500 font-semibold">
          Action id: {actionId}
        </p>
      </div>
    </div>
  );
};

const Actions = () => {
  const [actions, setActions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getActions = async () => {
      try {
        const result = await axios.post(
          productsAPI.products.getMostCommonActionsBeforeSale,
          {},
          {
            cancelToken: source.token,
          }
        );

        if (result.status === 200) {
          setActions(Object.entries(result?.data));
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setActions(null);
        setError(error.message);
      }
    };

    getActions().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, []);

  return (
    <div className="px-4 py-4">
      <div className="grid grid-cols-5 gap-4">
        {actions &&
          actions.map((ae) => (
            <Action
              key={ae[0]}
              actionName={ae[1]?.name}
              actionId={ae[0]}
              actionProgress={ae[1]?.change}
            />
          ))}
      </div>
    </div>
  );
};

export default Actions;
