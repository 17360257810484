import React from "react";

const PingCircle = () => {
  return (
    <div className="flex justify-center w-10 h-10">
      <span className="flex h-0 w-0">
        <span className="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-purple-400 opacity-75" />
        <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
      </span>
    </div>
  );
};

export default PingCircle;
