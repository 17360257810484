import React, { useEffect, useState } from "react";
import NISSAPlaceHolder from "../../images/nissa_placeholder_image.png";
import axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";
import { useHistory } from "react-router-dom";
import { largeNumberFormat } from "../../utils/Utils";
import { API } from "../../services/config";

const CollectionProductRow = ({ product, index }) => {
  const history = useHistory();
  const { sales_quantity, sales_value, parent_code } = product;
  const [images, setImages] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const getImages = async () => {
      const jwt = await asyncLocalGet("token");
      try {
        const result = await axios.post(
          API.explore.presentationImages,
          {
            parent_code: parent_code,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
            cancelToken: source.token,
          }
        );
        if (result.status === 200) {
          setImages(result?.data?.images);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        }

        if (axios.isCancel(error)) {
          return "Request for product presentation image was canceled due to an error when fetching data.";
        }
        setImages(null);
      }
    };

    getImages().then((r) => r);
    return () => {
      source.cancel("Request for post presentation image was canceled.");
    };
  }, [history, parent_code]);

  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-bold text-gray-800 text-indigo-500">
            {index + 1}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="border-none">
            {images && images.length > 0 ? (
              <img
                src={`data:image/jpeg;base64,${images[0].toString("base64")}`}
                className="w-full h-32 object-contain rounded-lg shadow-lg"
              />
            ) : (
              <img
                src={NISSAPlaceHolder}
                className="object-cover w-16 h-24 rounded-lg shadow-lg"
              />
            )}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800 text-indigo-500">
            {parent_code}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="text-left font-medium text-green-500">
          {largeNumberFormat(sales_value, 4)} RON
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="text-left font-medium text-blue-500">
          {sales_quantity}
        </div>
      </td>
    </tr>
  );
};

export default CollectionProductRow;
