import React, { useState, useEffect } from "react";
import { API } from "../../services/config";
import * as axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";

const emailList = [
  {
    email: "mrizea@nissa.ro",
    admin: true,
  },
  { email: "apolei@nissa.ro", admin: false },
  { email: "lsimileanu@nissa.ro", admin: false },
  { email: "mpopescu@nissa.ro", admin: false },
  { email: "sbuzoianu@nissa.ro", admin: false },
  { email: "rmada@nissa.ro", admin: false },
  { email: "ataropa@nissa.ro", admin: false },
];

const UserTableRow = ({ user }) => {
  const { email, admin } = user;

  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="border-none">{email}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-semibold text-gray-800">
            {admin ? "Admin" : "Basic"}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap flex justify-center">
        {admin ? null : (
          <div className="flex">
            <button className="bg-red-300 hover:bg-red-600 py-1 px-2 flex mr-4 rounded-lg shadow-lg font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Delete user
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

const UsersTable = () => {
  const [users, setUsers] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getUsers = async () => {
      const jwt = await asyncLocalGet("token");

      try {
        let data = {
          username: "mrizea@nissa.ro",
        };

        const result = await axios.post(API.auth.getUsers, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          cancelToken: source.token,
        });

        if (result.status === 200) {
          setError(null);
          setUsers(result?.data);
        }
      } catch (error) {
        setError(error.message);
        setUsers(null);
      }
    };

    getUsers().then((r) => r);
  }, []);

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200 w-full">
      <header className="px-5 py-4 border-b border sticky top-16 z-40 bg-gray-50">
        <div className="flex flex-row justify-between">
          <h2 className="font-semibold text-gray-800 mt-2 text-lg">
            Active users on AIDAA
          </h2>
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Status</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Delete user</div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {users &&
                Object.entries(users).map((user) => (
                  <UserTableRow user={{ email: user[0], admin: user[1] }} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
