import React, { useState, useEffect } from "react";
import { largeNumberFormat } from "../../utils/Utils";
import * as axios from "axios";
import { productsAPI } from "../../services/config";

const InfoCapsule = ({ name, metric, svgComponent }) => {
  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-3 rounded-lg shadow-lg">
      <div className="flex flex-row bg-white shadow-sm rounded p-4">
        <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-100 text-blue-500">
          {svgComponent}
        </div>
        <div className="flex flex-col flex-grow ml-4">
          <div className="text-sm text-gray-500 font-semibold">{name}</div>
          <div className="font-bold text-lg">{metric}</div>
        </div>
      </div>
    </div>
  );
};

const GeneralInfo = () => {
  const [generalInfo, setGeneralInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getInfo = async () => {
      try {
        const result = await axios.post(
          productsAPI.products.generalInfo,
          {},
          {
            cancelToken: source.token,
          }
        );

        if (result.status === 200) {
          console.log(result?.data);
          setGeneralInfo(result?.data);
          setError(null);
        }
      } catch (error) {
        console.log(error);
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setGeneralInfo(null);
        setError(error.message);
      }
    };

    getInfo().then((r) => r);

    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Product information */}
      <div className="grid grid-cols-12 gap-4">
        {generalInfo && generalInfo?.total_sessions && (
          <InfoCapsule
            name={"Active users"}
            metric={generalInfo?.total_sessions}
            svgComponent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            }
          />
        )}

        {generalInfo && generalInfo?.sessions_with_cart && (
          <InfoCapsule
            name={"Active sessions (with cart)"}
            metric={generalInfo?.sessions_with_cart}
            svgComponent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            }
          />
        )}

        {generalInfo && generalInfo?.average_cart_value && (
          <InfoCapsule
            name={"Average cart value"}
            metric={
              parseFloat(generalInfo?.average_cart_value)
                .toFixed(2)
                .toString() + " RON"
            }
            svgComponent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
            }
          />
        )}

        {generalInfo && generalInfo?.most_desired_product && (
          <InfoCapsule
            name={"Most desired product"}
            metric={"ID: " + generalInfo?.most_desired_product}
            svgComponent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
              </svg>
            }
          />
        )}
      </div>
    </div>
  );
};

export default GeneralInfo;
