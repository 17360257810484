import React, { useEffect, useState } from "react";
import AIDAALogo from "../../images/logo.jpeg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ErrorAlert from "../../partials/login/ErrorAlert";
import SuccessAlert from "../../partials/login/SuccessAlert";
import PasswordValidationAlert from "../../partials/login/PasswordValidationAlert";
import { asyncLocalStore, SUCCESS } from "../../services/storage/local_storage";
import { API } from "../../services/config";
import { validate } from "../../services/password-validator/validator";

const STATUS = {
  on: "on",
  off: "off",
};

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [serverStatus, setServerStatus] = useState(STATUS.off);
  const [inputDisabledStatus, setInputDisabledStatus] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    history.push("/help");
  };

  // Check server status and disable buttons
  useEffect(() => {
    const getStatus = async () => {
      try {
        const result = await axios.get(API.status.status);
        if (result.status === 200) {
          setServerStatus(STATUS.on);
          setInputDisabledStatus(false);
        }
      } catch (e) {
        setServerStatus(STATUS.off);
        setInputDisabledStatus(true);
      }
    };
    getStatus().then((r) => r);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    const performLogin = async () => {
      try {
        const userInfo = new FormData();
        userInfo.append("username", email);

        /* Validate password */
        if (validate(password).length !== 0) {
          setValidationError(validate(password));
          return;
        }
        userInfo.append("password", password);

        const result = await axios.post(API.auth.login, userInfo);
        if (result.status === 200) {
          const jwtStoreResult = await asyncLocalStore(
            "token",
            result?.data?.access_token
          );
          // setMessage("Received 200");

          if (jwtStoreResult !== SUCCESS) {
            return new Error(
              "The browser storage is not working in this moment!"
            );
          }

          if (result?.data?.is_admin) {
            const adminStatus = await asyncLocalStore(
              "admin",
              result?.data?.is_admin
            );

            if (adminStatus !== SUCCESS) {
              return new Error(
                "The browser storage is not working in this moment!"
              );
            }
          }

          const storeAuthStatus = await asyncLocalStore("auth", "ok");
          if (storeAuthStatus !== SUCCESS) {
            return new Error(
              "The browser storage is not working in this moment!"
            );
          }

          //setTimeout(() => {
          //  history.push("/dashboard");
          //}, 1000);
          history.push("/dashboard");
          setMessage("Logged in successful");
          setError(null);
        }
      } catch (error) {
        setError(error.message);
        setMessage(null);
      }
    };

    performLogin().then((r) => r);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 border rounded-lg bg-white shadow-lg p-10">
        <div>
          <img
            className="mx-auto h-48 w-auto rounded-lg shadow-lg"
            src={AIDAALogo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Intră în cont
          </h2>
          <h3 className="mt-2 text-center">
            {serverStatus === STATUS.on ? (
              <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-full">
                Server is on
              </span>
            ) : (
              <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                Server is off
              </span>
            )}
          </h3>
        </div>
        <form className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address/username
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                disabled={inputDisabledStatus}
                value={email}
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2  placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm mb-4"
                placeholder="Email"
                onChange={(e) => onChangeEmail(e)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                disabled={inputDisabledStatus}
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2  placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm mb-4"
                placeholder="Password"
                onChange={(e) => onChangePassword(e)}
              />
            </div>
          </div>

          {/* Login features to activate in next sprint */}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                onClick={() => console.log("persistence")}
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <p
                className="font-medium text-indigo-600 hover:text-indigo-500"
                onClick={(e) => forgotPasswordHandler(e)}
              >
                Forgot your password?
              </p>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border-none text-sm font-medium rounded-lg shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={(e) => handleLogin(e)}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
              Accesează contul
            </button>
          </div>

          {/* Successful login message */}
          {message && <SuccessAlert message={message} />}

          {/* Error message */}
          {error && <ErrorAlert errorMessage={error} />}

          {/* Error message */}
          {validationError && (
            <PasswordValidationAlert messages={validationError} />
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
