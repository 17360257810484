import React, { useState, useEffect } from "react";
import {
  asyncLocalGet,
  asyncLocalStore,
} from "../../services/storage/local_storage";

const ExploreFilterForm = ({ onSearch }) => {
  const [productType, setProductType] = useState("");
  const [productId, setProductId] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [productSize, setProductSize] = useState("");
  const [productColor, setProductColor] = useState("");
  const [textSearch, setText] = useState("");

  /* Status */
  const [isActive, setIsActive] = useState(false);
  const [isLimitedEdition, setIsLimitedEdition] = useState(false);
  const [isEX, setIsEX] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);

  /* Populate all fields with local storage values */
  useEffect(() => {
    let ignore = false;
    /* Local storage data retrieval */
    const populate = async () => {
      if (!ignore) {
        setProductType((await asyncLocalGet("product_type")) || "");
        setProductId((await asyncLocalGet("product_id")) || "");
        setCollectionId((await asyncLocalGet("collection_id")) || "");
        setProductSize((await asyncLocalGet("product_size")) || "");
        setProductColor((await asyncLocalGet("product_color")) || "");
        setText((await asyncLocalGet("text_search")) || "");
        setIsActive((await asyncLocalGet("is_active")) === "yes" || false);
        setIsEX((await asyncLocalGet("is_ex")) === "yes" || false);
        setIsLimitedEdition(
          (await asyncLocalGet("is_limited_edition")) === "yes" || false
        );
        setOutOfStock((await asyncLocalGet("out_of_stock")) === "yes" || false);
      }
    };

    populate().then((r) => r);
    return () => {
      ignore = true;
    };
  }, []);

  /* Input handlers */
  const onChangeType = (e) => {
    setProductType(e.target.value);
    asyncLocalStore("product_type", e.target.value).then((r) => r);
  };

  const onChangeId = (e) => {
    setProductId(e.target.value);
    asyncLocalStore("product_id", e.target.value).then((r) => r);
  };

  const onChangeCollection = (e) => {
    setCollectionId(e.target.value);
    asyncLocalStore("collection_id", e.target.value).then((r) => r);
  };

  const onChangeSize = (e) => {
    setProductSize(e.target.value);
    asyncLocalStore("product_size", e.target.value).then((r) => r);
  };

  const onChangeColor = (e) => {
    setProductColor(e.target.value);
    asyncLocalStore("product_color", e.target.value).then((r) => r);
  };

  const onChangeTextSearch = (e) => {
    setText(e.target.value);
    asyncLocalStore("text_search", e.target.value).then((r) => r);
  };

  const onChangeActive = () => {
    if (isActive) asyncLocalStore("is_active", "no").then((r) => r);
    else asyncLocalStore("is_active", "yes").then((r) => r);
    setIsActive(!isActive);
  };

  const onLimitedEditionChange = () => {
    if (isLimitedEdition)
      asyncLocalStore("is_limited_edition", "no").then((r) => r);
    else asyncLocalStore("is_limited_edition", "yes").then((r) => r);
    setIsLimitedEdition(!isLimitedEdition);
  };

  const onEXChange = () => {
    if (isEX) asyncLocalStore("is_ex", "no").then((r) => r);
    else asyncLocalStore("is_ex", "yes").then((r) => r);
    setIsEX(!isEX);
  };

  const onOutOfStockChange = () => {
    if (outOfStock) asyncLocalStore("out_of_stock", "no").then((r) => r);
    else asyncLocalStore("out_of_stock", "yes").then((r) => r);
    setOutOfStock(!outOfStock);
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    onSearch(
      e,
      productColor,
      productSize,
      productId,
      collectionId,
      productType,
      textSearch,
      isLimitedEdition,
      isEX,
      isActive,
      outOfStock
    );
  };

  return (
    <div className="w-full bg-white p-5 rounded-lg shadow-lg bg-gray-50 mb-10">
      <form className="px-8 pt-0 pb-8 mb-4 rounded">
        <h3 className="pt-4 text-4xl text-left font-bold mb-10">
          Explore products
        </h3>
        {/*  1  */}
        <div className="border-none">
          <h1 className="text-3xl font-semibold mb-2"> Tag filters </h1>
          <hr className="h-4" />

          <div className="mb-6 grid grid-cols-4 gap-6">
            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Product Color
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow-lg appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: NG"
                onChange={(e) => onChangeColor(e)}
                value={productColor}
              />
            </div>
            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Product Size
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow-lg appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: M / 36"
                onChange={(e) => onChangeSize(e)}
                value={productSize}
              />
            </div>
            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-200 ml-3">
                List Price
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: 400,34"
                disabled={true}
              />
            </div>
          </div>

          {/*  2  */}
          <div className="mb-6 grid grid-cols-4 gap-6">
            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Product Id
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow-lg appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: RZ1337, RZ11582"
                onChange={(e) => onChangeId(e)}
                value={productId}
              />
            </div>

            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Collection Id
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow-lg appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: 121"
                onChange={(e) => onChangeCollection(e)}
                value={collectionId}
              />
            </div>

            <div className="md:ml-2">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Product Type
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-lg leading-tight text-gray-700 border-none
                        rounded shadow-lg appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="eg: RZ, RS"
                onChange={(e) => onChangeType(e)}
                value={productType}
              />
            </div>
          </div>
        </div>

        {/* Text search */}
        <div className="border-none">
          <h1 className="text-3xl font-semibold mb-2">Text filters</h1>
          <hr className="h-4" />
          <div className="mb-6 flex justify-start">
            <div className="w-full">
              <label className="block mb-2 text-lg font-bold text-gray-700 ml-3">
                Product Description or Name
              </label>
              <input
                type="search"
                className="w-2/3 bg-purple-white shadow-lg text-lg rounded-lg border-none
                        focus:outline-none focus:shadow-outline p-3"
                placeholder="Search for keywords in product description or name. eg: Black summer dress"
                onChange={(e) => onChangeTextSearch(e)}
                value={textSearch}
              />
            </div>
          </div>
        </div>
        <div className="border-none">
          <h1 className="text-3xl font-semibold mb-2">Status filters</h1>
          <hr className="h-4" />
          <div className="mb-6 md:flex md:justify-start">
            <label className="inline-flex items-center m-5">
              <input
                type="checkbox"
                className="form-checkbox h-8 w-8 px-3 py-2 mt-0 text-xl leading-tight text-gray-700
                        border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                onChange={() => onChangeActive()}
                checked={isActive}
              />
              <span className="ml-2">Match only active products</span>
            </label>

            <label className="inline-flex items-center m-5">
              <input
                type="checkbox"
                className="form-checkbox h-8 w-8 px-3 py-2 mt-0 text-sm leading-tight
                        text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                onChange={() => onEXChange()}
                checked={isEX}
              />
              <span className="ml-2">Match only EX products</span>
            </label>

            <label className="inline-flex items-center m-5">
              <input
                type="checkbox"
                className="form-checkbox h-8 w-8 px-3 py-2 mt-0 text-sm leading-tight
                        text-gray-700 border rounded shadow appearance-none focus:outline-none
                        focus:shadow-outline"
                onChange={() => onLimitedEditionChange()}
                checked={isLimitedEdition}
              />
              <span className="ml-2">Match only Limited Edition products</span>
            </label>

            <label className="inline-flex items-center m-5">
              <input
                type="checkbox"
                className="form-checkbox h-8 w-8 px-3 py-2 mt-0 text-sm leading-tight
                        text-gray-700 border rounded shadow appearance-none focus:outline-none
                        focus:shadow-outline"
                onChange={() => onOutOfStockChange()}
                checked={outOfStock}
              />
              <span className="ml-2">Match only out of stock products</span>
            </label>
          </div>
        </div>
        <div className="mb-6 md:flex md:justify-start">
          <button
            className="px-3 py-2 font-normal text-white bg-purple-600 rounded-lg hover:bg-purple-500
                    focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={(e) => onSearchHandler(e)}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default ExploreFilterForm;
