import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NissaLogo from "../images/nissa_logo.png";
import { asyncLocalGet } from "../services/storage/local_storage";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const [adminStatus, setAdminStatus] = useState(true);

  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    let as = true;
    const getStatus = async () => {
      const status = await asyncLocalGet("admin");
      if (as == undefined) {
        as = true;
      } else {
        as = status;
      }
    };
    setAdminStatus(as);
  });

  return (
    <div className="lg:w-64">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div>
          <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
            NISSA
          </h3>
          <ul className="mt-3">
            {/* Dashboard */}

            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/dashboard"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "dashboard" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "dashboard" && "text-indigo-500"
                      }`}
                      d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                    />
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "dashboard" && "text-indigo-600"
                      }`}
                      d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                    />
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "" && "text-indigo-200"
                      }`}
                      d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                    />
                  </svg>
                  <span className="text-sm font-medium">Dashboard</span>
                </div>
              </NavLink>
            </li>
            {/* Explore */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/explore"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "explore" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "explore" && "text-indigo-300"
                      }`}
                      d="M7 0l6 7H8v10H6V7H1z"
                    />
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "explore" && "text-indigo-500"
                      }`}
                      d="M18 7v10h5l-6 7-6-7h5V7z"
                    />
                  </svg>
                  <span className="text-sm font-medium">Explore</span>
                </div>
              </NavLink>
            </li>
            {/* Social */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page.startsWith("social") && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/social"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page.startsWith("social") && "hover:text-gray-200"
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex flex-grow">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className={`fill-current text-gray-600 ${
                          page.startsWith("social-") && "text-indigo-500"
                        }`}
                        d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          page.startsWith("social") && "text-indigo-300"
                        }`}
                        d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                      />
                    </svg>
                    <span className="text-sm font-medium">Social</span>
                  </div>
                  {/* Icon */}
                  <div className="flex flex-shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                        page.startsWith("social") && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </NavLink>
              <ul
                className={`pl-9 mt-1 ${
                  !page.startsWith("social") && "hidden"
                }`}
              >
                <li className="mb-1 last:mb-0">
                  <NavLink
                    exact
                    to="/social-fb"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "social-fb" &&
                      "text-indigo-400 hover:text-indigo-400"
                    }`}
                  >
                    <span className="text-sm font-medium">Facebook</span>
                  </NavLink>
                </li>
                <li className="mb-1 last:mb-0">
                  <NavLink
                    exact
                    to="/social-ig"
                    className={`block text-gray-200 hover:text-white transition duration-150" ${
                      page === "social-ig" &&
                      "text-indigo-400 hover:text-indigo-400"
                    }`}
                  >
                    <span className="text-sm font-medium">Instagram</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* Collections */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "collections" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/collections"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "collections" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "collections" && "text-indigo-500"
                      }`}
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                  <span className="text-sm font-medium">Collections</span>
                </div>
              </NavLink>
            </li>
            {/* Campaigns */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "campaigns" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/campaigns"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "campaigns" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "campaigns" && "text-indigo-500"
                      }`}
                      d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
                    />
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "campaigns" && "text-indigo-300"
                      }`}
                      d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
                    />
                  </svg>
                  <span className="text-sm font-medium">Campaigns</span>
                </div>
              </NavLink>
            </li>

            {/* User Analytics */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "user_analytics" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/user_analytics"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "user_analytics" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      className={`text-gray-400 ${
                        page === "user_analytics" && "text-indigo-300"
                      }`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                  <span className="text-sm font-medium ml-2">
                    User Analytics
                  </span>
                </div>
              </NavLink>
            </li>

            {/* Tasks */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "stores" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/stores"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "stores" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      className={`text-gray-600 ${
                        page === "stores" && "text-indigo-500"
                      }`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                    <path
                      className={`text-gray-400 ${
                        page === "stores" && "text-indigo-300"
                      }`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                    <path
                      className={`text-gray-400 ${
                        page === "stores" && "text-indigo-300"
                      }`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                  </svg>
                  <span className="text-sm font-medium ml-3">Stores</span>
                </div>
              </NavLink>
            </li>
            {/* Settings */}
            {adminStatus && (
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  page === "settings" && "bg-gray-900"
                }`}
              >
                <NavLink
                  exact
                  to="/settings"
                  className={`block text-gray-200 hover:text-white transition duration-150 ${
                    page === "settings" && "hover:text-gray-200"
                  }`}
                >
                  <div className="flex flex-grow">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className={`fill-current text-gray-600 ${
                          page === "settings" && "text-indigo-500"
                        }`}
                        d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          page === "settings" && "text-indigo-300"
                        }`}
                        d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
                      />
                      <path
                        className={`fill-current text-gray-600 ${
                          page === "settings" && "text-indigo-500"
                        }`}
                        d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          page === "settings" && "text-indigo-300"
                        }`}
                        d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
                      />
                    </svg>
                    <span className="text-sm font-medium">Admin</span>
                  </div>
                </NavLink>
              </li>
            )}

            {/* No PIM Products */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "no-pim-products" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/pim"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "pim" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "pim" && "text-indigo-500"
                      }`}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    />
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "pim" && "text-indigo-300"
                      }`}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    />
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "pim" && "text-indigo-500"
                      }`}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    />
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "pim" && "text-indigo-300"
                      }`}
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                    />
                  </svg>
                  <span className="text-sm font-medium">Pimcore</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
