import React from "react";
import { largeNumberFormat } from "../../../utils/Utils";

const StoreTarget = ({ targetName, targetProgress, bgColor, targetAmount }) => {
  return (
    <div className="text-center text-light-blue-500">
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div className="border-none flex justify-between w-2/3">
            <span
              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-${bgColor}-200`}
            >
              {targetName}
            </span>
            <span
              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-${bgColor}-200`}
            >
              {largeNumberFormat(targetAmount, 4)} RON
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-pink-600">
              {parseFloat(targetProgress).toFixed(2)}%
            </span>
          </div>
        </div>
        <div
          className={`overflow-hidden h-2 mb-4 text-xs flex rounded bg-${bgColor}-200`}
        >
          <div
            style={{ width: `${targetProgress}%` }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${bgColor}-500`}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreTarget;
