import React from "react";
import { largeNumberFormat } from "../../../utils/Utils";

const SalesDonutTableRow = ({ product }) => {
  const { color, stats, label } = product;

  return (
    <tr>
      <td className="p-2 whitespace-nowrap" style={{ background: color }}>
        <div className="flex items-center">
          <div className="font-medium text-gray-800" />
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-gray-800">{label}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-gray-800 text-indigo-500">
            {stats.sales_quantity}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-gray-800 text-indigo-500">
            {stats.total_quantity}
          </div>
        </div>
      </td>
      {stats.sales_value && (
        <td className="p-2 whitespace-nowrap">
          <div className="flex items-center justify-center">
            <div className="font-medium text-green-500">
              {largeNumberFormat(stats.sales_value, 2)}
            </div>
          </div>
        </td>
      )}
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-green-500">
            {largeNumberFormat(stats.expected_value, 2)}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-green-500">
            {parseFloat(stats.price_performance).toFixed(2)}%
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center justify-center">
          <div className="font-medium text-green-500">
            {parseFloat(stats.stock_performance).toFixed(2)}%
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SalesDonutTableRow;
