import React, { useState } from "react";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import AnalyticsCampaignsTable from "../../partials/campaigns/google_analytics/AnalyticsCampaignsTable";

const Campaigns = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Google analytics campaigns table */}
            <div className="grid grid-cols-4 gap-6 sm:grid-cols-1">
              <AnalyticsCampaignsTable />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Campaigns;
