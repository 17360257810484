import React, { useEffect, useState, lazy, Suspense } from "react";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SearchBounce from "../../partials/animations/SearchBounce";
import { randomKey } from "../../utils/Utils";
import { asyncLocalGet } from "../../services/storage/local_storage";
import AllSizesCard from "../../partials/child-products/AllSalesCard";
import { API } from "../../services/config";
import ChildProductsError from "../../partials/child-products/error/Error";

const ChildProduct = lazy(() =>
  import("../../partials/child-products/ChildProduct")
);

const ChildProducts = () => {
  /* Navigation state params */
  const history = useHistory();
  const children = history.location.state.children;
  const listPrice = history.location.state.list_price;
  const parentCode = history.location.state.parentCode;
  const allBarcodes = history.location.state.children.map((c) => c?.barcode);

  /* Inner state params */
  const [error, setError] = useState(null);
  const [images, setImages] = useState(history.location.state.images);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleBackNavigation = (e) => {
    e.preventDefault();
    history.goBack();
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getAllProductImages = async () => {
      const jwt = await asyncLocalGet("token");
      try {
        const result = await axios.post(
          API.explore.productImages,
          {
            parent_code: parentCode,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        if (result.status === 200) {
          setImages(result?.data?.images);
          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for child products images canceled due to an error when fetching data.";
        }
        setError("Images cannot be loaded!");
        setImages(null);
      }
    };

    getAllProductImages().then((r) => r);
    return () => {
      source.cancel("Request for child products images was canceled.");
    };
  }, [parentCode]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Child product navigation button */}
        <div className="border-none m-6">
          <button
            className="px-3 py-2 font-normal flex text-white bg-purple-600 rounded-lg hover:bg-purple-500 focus:outline-none focus:shadow-outline"
            type="button"
            onClick={(e) => handleBackNavigation(e)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back
          </button>
        </div>

        {/* Child product image list */}
        <div className="flex justify-center h-1/3 mb-10">
          <div className="flex justify-items-center h-full bg-white rounded-lg shadow-lg overflow-x-auto ml-2 mr-2">
            {/* Product images */}
            {images &&
              images.map((image) => (
                <img
                  src={`data:image/jpeg;base64,${image.toString("base64")}`}
                  width="auto"
                  height="auto"
                  className="rounded-lg shadow-xl m-5"
                  key={randomKey(8, 3)}
                />
              ))}

            {/* Error banner */}
            {error && <ChildProductsError message={error} />}
          </div>
        </div>

        {/* Child product all sizes graph */}
        <div className="p-10">
          <AllSizesCard parentCode={parentCode} />
        </div>

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            {children && (
              <Suspense
                fallback={
                  <div className="w-full flex justify-center">
                    <SearchBounce />
                  </div>
                }
              >
                <div className="flex justify-between flex-wrap bg-white shadow-lg rounded-lg p-10">
                  {children.map((c) => (
                    <ChildProduct
                      childProduct={c}
                      parentListPrice={listPrice}
                      allBarcodes={allBarcodes}
                      key={randomKey(8, 7)}
                    />
                  ))}
                </div>
              </Suspense>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChildProducts;
