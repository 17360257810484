import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { asyncLocalGet } from "../../services/storage/local_storage";
import axios from "axios";
import CollectionFilterForm from "../../partials/collections/CollectionFilterForm";
import CollectionCompositionDonutCard from "../../partials/collections/composition/CollectionCompositionDonutCard";
import CollectionBestsellerTable from "../../partials/collections/CollectionBestsellerTable";
import CollectionSalesDonutCard from "../../partials/collections/sales/CollectionSalesDonutCard";
import { API } from "../../services/config";

const colors = require("tailwindcss/colors");

const Collections = () => {
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [collectionID, setCollectionID] = useState(null);
  const [collectionParentCodes, setCollectionParentCodes] = useState(null);
  const [colorsArray, setColorsArray] = useState(null);

  const searchHandler = (e, collectionID) => {
    e.preventDefault();

    const performSearch = async () => {
      setCollectionID(collectionID);
      setColorsArray(
        Object.entries(colors)
          .filter((c) => c[0] !== "white" && c[0] !== "black")
          .map((color) => color[1][500])
      );

      const jwt = await asyncLocalGet("token");
      const data = {};

      if (collectionID !== "") {
        data.collection = parseInt(collectionID);
      }

      try {
        const result = await axios.post(API.collection.composition, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        if (result.status === 401 || result.status === 403) {
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
        if (result.status === 200) {
          setCollectionParentCodes(result?.data?.parent_codes);
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
        setCollectionParentCodes(null);
      }
    };

    performSearch().then((r) => r);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="w-full bg-white p-5 rounded-lg shadow-lg bg-gray-50 mb-10">
              <CollectionFilterForm onSearch={searchHandler} />
            </div>
            <div className="flex flex-col">
              {collectionID && (
                <div className="m-2">
                  <CollectionCompositionDonutCard collectionID={collectionID} />
                </div>
              )}
              {collectionID && (
                <div className="m-2">
                  <CollectionSalesDonutCard collectionID={collectionID} />
                </div>
              )}
            </div>
            {collectionParentCodes && (
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Table (Best Sellers Products by Qty) */}
                <CollectionBestsellerTable
                  title={"Top 30 Products"}
                  order={"descending"}
                  parentCodes={collectionParentCodes}
                />
              </div>
            )}
            {collectionParentCodes && (
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Table (Best Sellers Products by Qty) */}
                <CollectionBestsellerTable
                  title={"Worst 30 Products"}
                  order={"ascending"}
                  parentCodes={collectionParentCodes}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Collections;
