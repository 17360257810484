import React, { lazy, Suspense } from "react";
import SearchBounce from "../animations/SearchBounce";
import ErrorBanner from "./ErrorBanner";

const Product = lazy(() => import("./Product"));

const ProductsContainer = ({ products, error }) => {
  return (
    <div className="grid grid-cols-12 gap-6">
      <Suspense
        fallback={
          <div className="w-full flex justify-center">
            <SearchBounce />
          </div>
        }
      >
        {Object.keys(products).map((product) => (
          <Product
            key={product}
            product={products[product]}
            parentCode={product}
          />
        ))}
      </Suspense>
      {error && <ErrorBanner message={error} />}
      {products && Object.keys(products).length === 0 && (
        <ErrorBanner message={"There are no products!"} />
      )}
    </div>
  );
};

export default ProductsContainer;
