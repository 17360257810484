import React, { useState } from "react";
import AdminBanner from "../../partials/settings/AdminBanner";
import UsersTable from "../../partials/settings/UsersTable";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Generator from "../../services/password-generator/generator";
import { API } from "../../services/config";
import * as axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";

const Settings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState(null);
  const [error, setError] = useState(null);
  const [succes, setSuccess] = useState(false);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const handlePasswordGeneration = (e) => {
    e.preventDefault();
    setGeneratedPassword(Generator(20));
  };

  const handleUserInfoSubmit = (e) => {
    e.preventDefault();

    if (password === null) {
      setError("The password is empty");
      return;
    }

    if (email === null) {
      setError("The email is empty");
      return;
    }

    // if (password !== null && email !== null) {
    //   const registerUser = async () => {
    //     const jwt = await asyncLocalGet("token");
    //     try {
    //       const data = {
    //         username: email,
    //         password: password,
    //       };
    //
    //       const result = await axios.post(API.auth.register, data, {
    //         headers: {
    //           Authorization: `Bearer ${jwt}`,
    //         },
    //       });
    //
    //       if (result.status === 200) {
    //         setError(null);
    //         console.log("aljdlkajdlkajdkaks");
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    //
    //   registerUser().then((r) => r);
    // }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 py-8">
            <AdminBanner />
            <div className="flex bg-gray-100">
              <div className="m-auto">
                <div>
                  <h1 className="text-xl font-extrabold">Create new users</h1>
                  <div className="mt-5 bg-white rounded-lg shadow">
                    <div className="flex">
                      <div className="flex py-5 pl-5 overflow-hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                          />
                        </svg>
                        <h1 className="inline text-xl font-semibold leading-none ml-2">
                          User details
                        </h1>
                      </div>
                    </div>
                    <div className="mt-5 p-5">
                      <div className="p-2 bg-green-200 rounded-lg shadow-lg flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <span className="font-semibold ml-2">
                          Please consider using a secure password that can be
                          generated with the button bellow
                        </span>
                      </div>
                    </div>
                    <div className="px-5 pb-5">
                      <input
                        placeholder="Email / Username"
                        type="email"
                        className=" text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200  focus:border-purple-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <input
                        placeholder="Password"
                        type="email"
                        className=" text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200  focus:border-purple-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="px-5 "></div>
                    <hr className="mt-4" />
                    <div className="flex flex-row-reverse p-3">
                      <div className="flex-initial pl-3">
                        <button
                          type="button"
                          className="flex items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize   bg-black rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out"
                          onClick={(e) => handleUserInfoSubmit(e)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#FFFFFF"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                              d="M5 5v14h14V7.83L16.17 5H5zm7 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-8H6V6h9v4z"
                              opacity=".3"
                            />
                            <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                          </svg>
                          <span className="pl-2 mx-1">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <button
                    type="button"
                    className="flex items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize   bg-black rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out"
                    onClick={(e) => handlePasswordGeneration(e)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                    <span className="pl-2 mx-1">Generate secure password</span>
                  </button>

                  {generatedPassword && (
                    <p className="font-semibold text-gray-800 p-4 text-center bg-white mt-10 rounded-lg shadow-lg">
                      {generatedPassword}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full p-10 flex justify-center">
              <UsersTable />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Settings;
