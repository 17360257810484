import React from "react";

const CollectionError = ({ message }) => {
    return (
        <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-10 text-center"
            role="alert"
        >
            <strong className="font-bold">{message}</strong>
        </div>
    );
};

export default CollectionError;
