import React, { useState, useEffect } from "react";
import axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";
import CollectionProductRow from "./CollectionProductRow";
import { useHistory } from "react-router-dom";
import CollectionError from "./erorr/CollectionError";
import { API } from "../../services/config";

const CollectionBestsellerTable = ({ parentCodes, order, title }) => {
  const history = useHistory();
  const [bestSellersData, setBestSellersData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setBestSellersData(null);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const getBestSellersData = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {
        ordering: order,
        product_params: {
          parent_code: parentCodes,
        },
        period_delta: {
          years: 3,
        },
      };
      try {
        const result = await axios.post(API.dashboard.productMetrics, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          cancelToken: source.token,
        });

        if (result.status === 200) {
          if (result?.data?.result.length > 0) {
            setBestSellersData(result?.data?.result.splice(0, 30));
          } else {
            setBestSellersData(null);
          }

          setError(null);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        }

        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }
        setError(error);
      }
    };

    getBestSellersData().then((r) => r);
    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [history, parentCodes]);

  return (
    <div>
      {error && (
        <div className="flex justify-center text-center">
          <CollectionError message={"Could not load data!"} />
        </div>
      )}
      {bestSellersData && (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">{title}</h2>
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                  <tr>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-center">#</div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Product Image
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Product Name
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Revenue</div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Quantity</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}

                <tbody className="text-sm divide-y divide-gray-100">
                  {bestSellersData.map((product, index) => (
                    <CollectionProductRow
                      index={index}
                      product={product}
                      key={index}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionBestsellerTable;
