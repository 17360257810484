import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfigFile from "../css/tailwind.config";
import { LabelsAndColorsMap } from "./LabelsAndColorsMap";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

/**
 * Convert hex color to RGB
 *
 * @param h
 * @returns {`${number},${number},${number}`}
 */
export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

/**
 * Currency format
 *
 * @param value
 * @returns a currency like formatted number
 */
export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "RON",
    maximumSignificantDigits: 2,
    notation: "compact",
  }).format(value);

/**
 * Large number format
 *
 * @param value
 * @returns a large number compact formatted
 */
export const largeNumberFormat = (value, numDigits) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: numDigits,
    notation: "compact",
  }).format(value);

/**
 * Generating uniq keys for react child components.
 *
 * @param radix: An integer in the range 2 through 36 specifying the base to use for representing numeric values.
 * @param ss: Ann integer in the range 0 to inf. specifying the number of tokens taken from the generated string.
 * @returns {string}
 */
export const randomKey = (radix, ss) => {
  return (Math.random() + 1).toString(radix).substring(ss);
};

/**
 * Social media emoji mapper for cards display purpose
 *
 * @param name
 * @returns {string}
 */
export const getEmoji = (name) => {
  switch (name) {
    case "haha":
      return "😂";
    case "love":
      return "❤️";
    case "like":
      return "👍";
    case "sad":
      return "😢";
    case "angry":
      return "😡";
    case "wow":
      return "😮";
    default:
      return "";
  }
};

/**
 * Builds a color array using a label-to-color mapper.
 *
 * @param labels - array of clothing labels/categories
 * @returns {*[]} - array of colors
 */
export const constructColors = (labels) => {
  let colors = [];
  for (let i = 0; i < labels.length; i++) {
    if (Object.keys(LabelsAndColorsMap).indexOf(labels[i]) > 0) {
      //found
      colors.push(LabelsAndColorsMap[labels[i]]);
    } else {
      //not found, insert default color
      colors.push(LabelsAndColorsMap.DEFAULT);
    }
  }
  return colors;
};

/**
 * Receives a Date object and constructs a date string with the following format => YYYY-MM-DD
 *
 * @param date - received date
 * @returns {string} - string with YYYY-MM-DD format
 */
export const getDateString = (date) => {
  return `${date.getFullYear()}-${(
    "0" + (date.getMonth() + 1).toString()
  ).slice(-2)}-${("0" + date.getDate().toString()).slice(-2)}`;
};
