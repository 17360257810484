import React, { useState, lazy, Suspense } from "react";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import LoadingBanner from "../../partials/dashboard/utils/LoadingBanner";
import { Metrics } from "../../partials/dashboard/utils/Metrics";

/**
 * Dashboard cards (lazy components)
 */
const SalesByStorePie = lazy(() =>
  import("../../partials/dashboard/sales_by_store_pie/SalesByStorePie")
);

const SalesByStoreTable = lazy(() =>
  import("../../partials/dashboard/sales_by_store_table/SalesByStoreTable")
);

const BestSellersTable = lazy(() =>
  import("../../partials/dashboard/best_sellers_table/BestSellersTable")
);

const OnlineVsRetail = lazy(() =>
  import("../../partials/dashboard/online_vs_retail/OnlineVsRetail")
);

const SalesComparison = lazy(() =>
  import("../../partials/dashboard/sales_comparison/SalesComparison")
);

const MostViewedProductsTable = lazy(() =>
  import(
    "../../partials/dashboard/most_viewed_products/MostViewedProductsTable"
  )
);

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            <Suspense fallback={<LoadingBanner />}>
              {/* Large cards */}
              <div className="grid grid-cols-1 gap-6 mb-6">
                {/* Line chart (Total monthly sales by revenue / quantity) */}
                <SalesComparison
                  cardTitle={"Retail sales last 30 days"}
                  implicitLocation={false}
                />
              </div>

              <div className="grid grid-cols-1 gap-6 mb-6">
                {/* Line chart (Total online sales in online by revenue / quantity) */}
                <SalesComparison
                  cardTitle={"Online sales last 30 days"}
                  implicitLocation={true}
                />
              </div>

              <div className="grid grid-cols-5 gap-6 mb-6">
                {/* Bar chart (Online vs Retail) */}
                <OnlineVsRetail />
                {/* Doughnut chart (Sales by store) */}
                <SalesByStorePie />
              </div>

              <div className="grid grid-cols-4 gap-6 mb-6">
                {/* Table (Best Sellers Products by Qty) */}
                <BestSellersTable metric={Metrics.quantity} />
              </div>

              <div className="grid grid-cols-4 gap-6 mb-6">
                {/* Table (Best Sellers Products by Revenue) */}
                <BestSellersTable metric={Metrics.value} />
              </div>

              <div className="grid grid-cols-4 gap-6 mb-6">
                {/* Table (Most viewed products online) */}
                <MostViewedProductsTable />
              </div>

              <div className="grid grid-cols-8 gap-6 mb-6">
                {/* Table (Sales by store table) */}
                <SalesByStoreTable />
              </div>
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
