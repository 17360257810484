import React, { lazy, useEffect, useState } from "react";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  asyncLocalGet,
  asyncLocalStore,
} from "../../services/storage/local_storage";
import { API } from "../../services/config";
import ProductsContainer from "../../partials/explore/ProductsContainer";
import ExploreFilterForm from "../../partials/explore/ExploreFilterForm";

const Explore = () => {
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  /* Response parameters */
  const [products, setProducts] = useState({});
  const [error, setError] = useState(null);

  /* Populate all fields with local storage values */
  useEffect(() => {
    let ignore = false;
    /* Local storage data retrieval */
    const populate = async () => {
      if (!ignore) {
        setProducts(JSON.parse(await asyncLocalGet("products")) || {});
      }
    };

    populate().then((r) => r);
    return () => {
      ignore = true;
    };
  }, []);

  /* Search button handler */
  const searchHandler = (
    e,
    productColor,
    productSize,
    productId,
    collectionId,
    productType,
    textSearch,
    isLimitedEdition,
    isEX,
    isActive,
    outOfStock
  ) => {
    e.preventDefault();

    const performSearch = async () => {
      const jwt = await asyncLocalGet("token");
      const data = {
        filter: {
          color: productColor,
          size: productSize,
          product_code: productId,
          collection: Number.parseInt(collectionId),
          product_type: productType,
          text_search: textSearch,
          is_limited_edition: isLimitedEdition,
          is_ex: isEX,
          is_active: isActive,
        },
        start: 0,
        end: 50,
        reset: true,
      };

      if (outOfStock === true) {
        data.out_of_stock = true;
      }

      try {
        const result = await axios.post(API.explore.filter, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (result.status === 200) {
          setProducts(result?.data);
          await asyncLocalStore("products", JSON.stringify(result?.data));
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push("/");
          }
        }

        setError(error.message);
        setProducts(null);
      }
    };

    performSearch().then((r) => r);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Filter form */}
            <ExploreFilterForm onSearch={searchHandler} />

            {/* Cards */}
            {products && (
              <ProductsContainer products={products} error={error} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Explore;
