import React, { useState } from "react";

const SocialMediaProductsContext = React.createContext(undefined, undefined);

const SocialMediaProductsContextProvider = ({ children }) => {
  const [selectedProductsForAdd, setSelectedProductsForAdd] = useState([]);
  const [selectedProductsForDel, setSelectedProductsForDel] = useState([]);

  return (
    <SocialMediaProductsContext.Provider
      value={{
        setSelectedProductsForDel,
        selectedProductsForDel,
        setSelectedProductsForAdd,
        selectedProductsForAdd,
      }}
    >
      {children}
    </SocialMediaProductsContext.Provider>
  );
};

export { SocialMediaProductsContextProvider, SocialMediaProductsContext };
