import { tailwindConfig } from "../../../utils/Utils";

const Colors = [
  tailwindConfig().theme.colors.blue[300],
  tailwindConfig().theme.colors.red[300],
  tailwindConfig().theme.colors.green[300],
  tailwindConfig().theme.colors.purple[300],
];

export { Colors };
