import React, { useEffect, useState } from "react";

const TargetEditForm = ({ updateCallback }) => {
  const [firstTargetValue, setFirstTargetValue] = useState(0);
  const [secondTargetValue, setSecondTargetValue] = useState(0);
  const [thirdTargetValue, setThirdTargetValue] = useState(0);
  const [fourthTargetValue, setFourthTargetValue] = useState(0);

  const handleFirstTargetValueOnChange = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setFirstTargetValue("");
    } else {
      setFirstTargetValue(parseInt(e.target.value));
    }
  };

  const handleSecondTargetValueOnChange = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setSecondTargetValue("");
    } else {
      setSecondTargetValue(parseInt(e.target.value));
    }
  };

  const handleThirdTargetValueOnChange = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setThirdTargetValue("");
    } else {
      setThirdTargetValue(parseInt(e.target.value));
    }
  };

  const handleFourthTargetValueOnChange = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setFourthTargetValue("");
    } else {
      setFourthTargetValue(parseInt(e.target.value));
    }
  };

  const handleStoreTargetsUpdate = (e) => {
    e.preventDefault();
    updateCallback(
      e,
      firstTargetValue,
      secondTargetValue,
      thirdTargetValue,
      fourthTargetValue
    );
  };

  return (
    <div className="flex rounded-lg shadow-lg bg-gray-50 p-2">
      <div className="mb-3 last:mb-0">
        <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">
          Edit targets for store
        </div>
        {/* Targets form */}
        <form className="mt-2 space-y-6 p-2">
          <div className="border-none -space-y-px flex justify-between p-2">
            <div className="mt-2">
              <span
                className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-blue-200`}
              >
                First Target
              </span>
            </div>
            <div className="ml-2">
              <input
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2
                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm mb-4 font-semibold"
                onChange={(e) => handleFirstTargetValueOnChange(e)}
                value={firstTargetValue}
              />
            </div>
          </div>
          <div className="border-none -space-y-px flex justify-between p-2">
            <div className="mt-2">
              <span
                className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-pink-200`}
              >
                Second Target
              </span>
            </div>
            <div className="ml-2">
              <input
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2
                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm mb-4 font-semibold"
                onChange={(e) => handleSecondTargetValueOnChange(e)}
                value={secondTargetValue}
              />
            </div>
          </div>
          <div className="border-none -space-y-px flex justify-between p-2">
            <div className="mt-2">
              <span
                className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-green-200`}
              >
                Third Target
              </span>
            </div>
            <div className="ml-2">
              <input
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2
                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm mb-4 font-semibold"
                onChange={(e) => handleThirdTargetValueOnChange(e)}
                value={thirdTargetValue}
              />
            </div>
          </div>
          <div className="border-none -space-y-px flex justify-between p-2">
            <div className="mt-2">
              <span
                className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-purple-200`}
              >
                Fourth Target
              </span>
            </div>
            <div className="ml-2">
              <input
                className="appearance-none rounded-lg shadow-lg border-none relative block w-full px-3 py-2
                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm mb-4 font-semibold"
                onChange={(e) => handleFourthTargetValueOnChange(e)}
                value={fourthTargetValue}
              />
            </div>
          </div>
          <div className="border-none -space-y-px flex justify-between p-2">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
              onClick={(e) => handleStoreTargetsUpdate(e)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TargetEditForm;
