import React, { useState, useEffect } from "react";
import NISSALogo from "../../images/nissa_logo.png";
import axios from "axios";
import { asyncLocalGet } from "../../services/storage/local_storage";
import { useHistory } from "react-router-dom";
import { Colors } from "../dashboard/sales_comparison/Colors";
import DashboardError from "../dashboard/erorr/DashboardError";
import AllSizesChart from "../../charts/all_sizes_chart/AllSizesChart";
import { getDateString, tailwindConfig } from "../../utils/Utils";
import Datepicker from "../actions/Datepicker";
import { API } from "../../services/config";

const AllSizesCard = ({ parentCode }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);

  const [isCustomDateEnabled, setIsCustomDateEnabled] = useState(false);
  const [dateInterval, setDateInterval] = useState(null);

  const [time, setTime] = useState({ timeAmount: 1, timeUnit: "years" });

  const onCustomDatEnabledChange = () => {
    setIsCustomDateEnabled(!isCustomDateEnabled);
  };

  const handleTimePeriodChange = (e) => {
    const { timeAmount, timeUnit } = JSON.parse(e.target.value);
    setTime({ timeAmount: timeAmount, timeUnit: timeUnit });
  };

  const datePickerHandler = (selectedDates) => {
    setDateInterval(
      `${getDateString(new Date(selectedDates[0]))}/${getDateString(
        new Date(selectedDates[1])
      )}`
    );
  };
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    let data = {
      granularity: "day",
      product_params: { parent_code: [parentCode] },
    };

    if (isCustomDateEnabled) {
      // we need to set a date interval
      if (dateInterval) {
        data.interval = dateInterval;
      }
    } else {
      switch (time.timeUnit) {
        case "weeks":
          data.period_delta = { weeks: time.timeAmount };
          break;
        case "months":
          data.period_delta = { months: time.timeAmount };
          break;
        case "years":
          data.period_delta = { years: time.timeAmount };
          break;
        default:
          data.period_delta = { years: 4 };
          break;
      }
    }

    const getSalesData = async () => {
      const jwt = await asyncLocalGet("token");

      try {
        const result = await axios.post(API.explore.discountedSales, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          cancelToken: source.token,
        });

        if (result.status === 200) {
          setChartData({
            labels: Object.entries(result?.data?.result).map(
              (e) => e[1]?.timestamp.split("T")[0]
            ),
            datasets: [
              {
                label: "All sizes sales",
                data: Object.entries(result?.data?.result).map((e) => {
                  return {
                    y: e[1]?.sales_value / e[1]?.sales_quantity,
                    x: e[1]?.timestamp.split("T")[0],
                    r: e[1]?.sales_quantity * 2,
                  };
                }),
                borderColor: tailwindConfig().theme.colors.blue[500],
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: Colors[0],
                clip: 50,
              },
            ],
          });

          setError(null);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return "Request for social media posts was canceled due to an error when fetching data.";
        }

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        }
        setError(error.message);
      }
    };

    getSalesData().then((r) => r);
    return () => {
      source.cancel("Request for social media posts was canceled.");
    };
  }, [history, parentCode, dateInterval, time]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-lg border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          <div className="mb-6">
            <img src={NISSALogo} width="64" height="64" alt="Nissa logo" />
          </div>
          <div className="flex flex-row justify-items-end h-5">
            <Datepicker
              datePickerHandler={datePickerHandler}
              visible={isCustomDateEnabled}
            />
            {!isCustomDateEnabled && (
              <div className="flex flex-row">
                <div className="p-2 border-none rounded-xl">
                  <label className="p-0 font-semibold text-sm">Previous:</label>
                  <select
                    className="border-none appearance-none pt-0 pb-0 font-semibold text-sm"
                    onChange={(e) => handleTimePeriodChange(e)}
                  >
                    {[1, 2, 3].map((i) => (
                      <option
                        key={i}
                        value={JSON.stringify({
                          timeAmount: i,
                          timeUnit: "weeks",
                        })}
                      >
                        {i} {i === 1 ? "week" : "weeks"}
                      </option>
                    ))}

                    {[1, 2, 3].map((i) => (
                      <option
                        key={i}
                        value={JSON.stringify({
                          timeAmount: i,
                          timeUnit: "months",
                        })}
                      >
                        {i} {i === 1 ? "month" : "months"}
                      </option>
                    ))}

                    {/* Default value */}
                    <option
                      value={JSON.stringify({
                        timeAmount: 1,
                        timeUnit: "years",
                      })}
                      selected={true}
                    >
                      1 year
                    </option>

                    {[2, 3].map((i) => (
                      <option
                        key={i}
                        value={JSON.stringify({
                          timeAmount: i,
                          timeUnit: "years",
                        })}
                      >
                        {i} years
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="mt-2 ml-5">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-500"
                onChange={() => onCustomDatEnabledChange()}
                checked={isCustomDateEnabled}
              />
              <span className="ml-2 text-gray-700 font-semibold">
                Custom date
              </span>
            </div>
          </div>
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">
          All sizes sales for {parentCode}
        </h2>
      </div>

      {/* Chart */}
      {chartData && (
        <div className="flex-grow">
          {/* Change the height attribute to adjust the chart height */}
          <AllSizesChart data={chartData} width={595} height={400} />
        </div>
      )}

      {/* Error */}
      {error && <DashboardError message={error} />}
    </div>
  );
};

export default AllSizesCard;
