import React from "react";
import AIDAALogo from "../../images/logo.jpeg";
import { useHistory } from "react-router-dom";

const Splash = () => {
  const history = useHistory();
  const handleLoginPageNavigation = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  return (
    <div className="h-screen pb-14 bg-right bg-cover bg-splash">
      <div className="w-full container mx-auto p-6">
        <div className="w-full flex items-center justify-between">
          <a
            className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            href="#"
          >
            <svg
              className="h-8 fill-current text-indigo-600 pr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z" />
            </svg>
            AIDAA
          </a>
          <div className="flex w-1/2 justify-end content-center">
            <button
              className="flex text-purple-500 font-semibold text-center p-2 md:h-auto md:p-4 bg-green-200 border-none rounded-lg shadow-lg"
              onClick={(e) => handleLoginPageNavigation(e)}
            >
              <span className="border-none mr-2">Go to platform</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="border-none p-10 flex flex-col mt-96">
        <div className="container mx-auto flex justify-center">
          <h1 className="text-3xl md:text-5xl text-purple-800 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
            Artificial Intelligence Data Automation Assistant
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Splash;
